import React from 'react';
import classes from './PrimeTabMenu.module.scss';
import {RiHome4Fill} from 'react-icons/ri';
import AnalyticsIcon from 'assets/images/primeMenu/AnalyticsSVG.svg';
import ContactsIcon from 'assets/images/primeMenu/ContactsSVG.svg';
import LinkIcon from 'assets/images/primeMenu/LinkSVG.svg';
import AccountIcon from 'assets/images/primeMenu/AccountsSVG.svg';
import TeamsIcon from 'assets/images/primeMenu/Teams.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import * as actions from 'store/actions';
import {connect} from 'react-redux';
import SVG from 'react-inlinesvg';
import {ROUTES_PATHS, USER_ROLE} from "../../../common/enums";

export const MENU_ITEMS = {
    USER: [
        {path: ROUTES_PATHS.USER.HOME, icon: <RiHome4Fill size={23}/>, label: 'Home'},
        {path: ROUTES_PATHS.USER.ANALYTICS, icon: <SVG src={AnalyticsIcon} alt="Analytics"/>, label: 'Analytics'},
        {path: ROUTES_PATHS.USER.CONTACTS, icon: <SVG src={ContactsIcon} alt="Contacts"/>, label: 'Contacts'},
        {path: ROUTES_PATHS.USER.LINK, icon: <SVG src={LinkIcon} alt="Link"/>, label: 'Link'},
        {path: ROUTES_PATHS.USER.ACCOUNT, icon: <SVG src={AccountIcon} alt="Account"/>, label: 'Account'},
    ],
    COMPANY_ADMIN: [
        {path: ROUTES_PATHS.COMPANY_ADMIN.TEAMS, icon: <SVG src={TeamsIcon} alt="Teams"/>, label: 'Teams'},
    ]
};

const PrimeDesktopMenu = ({dropdownVisible, themeColor, userRole, onToggleExportImportDropdown}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onNavigate = (path) => {
        navigate(path);
        if (dropdownVisible) {
            onToggleExportImportDropdown();
        }
    };

    const isActive = (path) => location.pathname.includes(path);

    const renderMenuItem = (item) => (
        <div
            key={item.path}
            className={isActive(item.path) ? classes.TabOptionActive : classes.TabOptionInactive}
            onClick={() => onNavigate(item.path)}
        >
            {React.cloneElement(item.icon, {
                style: {fill: isActive(item.path) ? "white" : themeColor},
            })}
            <p style={{color: isActive(item.path) ? "white" : themeColor}}>{item.label}</p>
        </div>
    );

    return (
        <div className={classes.PrimeTabMenu}>
            <div className={classes.TabOptions}>
                {MENU_ITEMS.USER.map(renderMenuItem)}
                {userRole === USER_ROLE.COMPANY_ADMIN && MENU_ITEMS.COMPANY_ADMIN.map(renderMenuItem)}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    dropdownVisible: state.contact.exportImportDropdownVisible,
    themeColor: state.home.status.themeColor,
    userRole: state.auth.userRole,
});

const mapDispatchToProps = (dispatch) => ({
    onToggleExportImportDropdown: () => dispatch(actions.toggleExportImportDropdown()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimeDesktopMenu);
