import React, {useEffect, useState} from 'react';
import classes from './CompanyDeails.module.scss';
import CompanyName from '../../../../../../assets/images/Vector (Stroke).svg';
import {MdOutlineClose, MdOutlineMailOutline} from 'react-icons/md';
import {FiAnchor, FiGlobe} from 'react-icons/fi';
import UploadImage from '../../../../../../assets/images/upload.png'
import {connect} from "react-redux";
import {adjustColorBrightness, createGradient} from "../../../../../../shared/constants";
import {useDropzone} from "react-dropzone";
import imageCompression from "browser-image-compression";
import {toast} from "react-toastify";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import ImageCropper from "../../../../../CreateProfile/ImageCropper/ImageCropper";
import {getTeamsCompanyDetails, updateTeamsCompanyDetails, uploadCompanyLogo} from "../../../../../../store/actions";
import CompanyUpdateConfirmation from "./CompanyUpdateConfirmation/CompanyUpdateComfirmation";
import {ClipLoader} from "react-spinners";
import PhoneInput from "react-phone-input-2";
import ct from "countries-and-timezones";
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp} from "react-icons/fa";
import PrivacyModeDropdown from "../../../../../CreateProfile/VisibilityDropdown/PrivacyModeDropdown";
import {Tooltip} from 'antd';

const CompanyDetails = props => {

  const [cropperVisible, setCropperVisibility] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyWebSite, setCompanyWebsite] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [logo, setLogo] = useState('');
  const [updateConfirmPopupVisible, setUpdateConfirmPopupVisible] = useState(false);
  const [whatsapp, setWhatsapp] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebookVisibility, setFacebookVisibility] = useState(true);
  const [instagramVisibility, setInstagramVisibility] = useState(true);
  const [linkedinVisibility, setLinkedinVisibility] = useState(true);
  const [twitterVisibility, setTwitterVisibility] = useState(true);
  const [whatsappVisibility, setWhatsappVisibility] = useState(true);

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await props.onGetCompanyDetails();
      setCompanyName(data.name);
      setCompanyAddress(data.address);
      setCompanyEmail(data.email);
      setCompanyWebsite(data.website);
      setContactNumber(data.contactNo)
      setLogo(data.logo);
      setFacebook(data.facebook);
      setFacebookVisibility(data.facebookVisibility === null ? true : data.facebookVisibility);
      setInstagram(data.instagram);
      setInstagramVisibility(data.instagramVisibility === null ? true : data.instagramVisibility);
      setWhatsapp(data.whatsapp);
      setWhatsappVisibility(data.whatsappVisibility === null ? true : data.whatsappVisibility);
      setTwitter(data.twitter);
      setTwitterVisibility(data.twitterVisibility === null ? true : data.twitterVisibility);
      setLinkedin(data.linkedin);
      setLinkedinVisibility(data.linkedinVisibility === null ? true : data.linkedinVisibility);
    }
    fetchData();
  }, []);

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  const handlePhoneChange = (phone) => {
    setContactNumber(phone);
  };

  const handleWhatsappChange = (phone) => {
    setWhatsapp(phone);
  }

  let result = '';

  const onUpdateHandler = async () => {
    if (props.profilePicture.value.includes(';base64')) {
      const base64String = props.profilePicture.value;
      const byteCharacters = atob(base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: 'image/png'});
      const file = new File([blob], 'image.jpg', {type: 'image/png'});

      result = await props.onUploadCompanyLogo(file);
    } else {
      result = logo;
    }

    setUpdateConfirmPopupVisible(false);
    props.onUpdateCompanyDetails({
      address: companyAddress,
      contactNo: contactNumber,
      email: companyEmail,
      facebook,
      facebookVisibility,
      instagram,
      instagramVisibility,
      linkedin,
      linkedinVisibility,
      logo: result,
      name: companyName,
      twitter,
      twitterVisibility,
      website: companyWebSite,
      whatsapp,
      whatsappVisibility
    }, showToast)
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = '';

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const setImage = file => {
    if (file.type.split('/')[0] !== 'image') {
      showToast('Select only an image ');
    } else {
      imageCompression(file, {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      })
        .then(compressedImage => {
          return getBase64(compressedImage);
        })
        .then(result => {
          setLogo(result)
          // props.onChangeProfilePicture(result);
          setCropperVisibility(true);
        })
        .catch(() => console.log('Image select error'));
    }
  };

  const onDrop = React.useCallback(acceptedFiles => {
    setImage(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <section className={classes.CompanyDetails}>
      {cropperVisible ? (
        <Backdrop onClick={() => setCropperVisibility(false)}>
          <ImageCropper onClose={() => setCropperVisibility(false)}/>
        </Backdrop>
      ) : null}
      {updateConfirmPopupVisible ? (
        <Backdrop onClick={() => setUpdateConfirmPopupVisible(false)}>
          <CompanyUpdateConfirmation
            color={props.color}
            onClose={() => {
              setUpdateConfirmPopupVisible(false)
            }
            }
            onUpdate={onUpdateHandler}

          />
        </Backdrop>
      ) : null}
      <p className={classes.Title}>Company Details</p>
      <div className={classes.MiddleContainer}>
        <div
          className={classes.ImageUpload}
          {...getRootProps()}
          style={{borderColor: isDragActive ? '#dbb659' : '#424242'}}
        >
          <input {...getInputProps()} />

          <div>
            <div className={classes.ImageContainer}>
              {logo === '' || logo === null ? null : (
                <div
                  className={classes.ClearButton}
                  onClick={e => {
                    // props.onChangeProfilePicture('');
                    setLogo('');
                    e.stopPropagation();
                  }}
                >
                  <MdOutlineClose size={16} color="#C5C5C5"/>
                </div>
              )}
              <div className={classes.ImageWrapper}>
                <img
                  className={classes.Image}
                  src={
                    logo === '' || logo === null
                      ? UploadImage
                      : props.profilePicture.value.includes(';base64')
                        ? props.profilePicture.value
                        : logo
                  }
                  alt={"Upload"}
                />
              </div>
            </div>

          </div>
          <p>
            Upload or drag & drop your Company logo here
          </p>
          <button
            style={{
              color: props.color,
              borderColor: props.color
            }}
          >
            Browse Files
          </button>
        </div>

        <div className={classes.InputFields}>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <div className={classes.SecondLayer}>
              <img src={CompanyName} alt="Name"/>
              <input placeholder="Company Name" value={companyName}
                     onChange={event => setCompanyName(event.target.value)}/>
            </div>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <div className={classes.SecondLayer}>
              <FiAnchor/>
              <input placeholder="Company Address" value={companyAddress}
                     onChange={event => setCompanyAddress(event.target.value)}/>
            </div>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <div className={classes.SecondLayer}>
              <MdOutlineMailOutline/>
              <input placeholder="Company Email" value={companyEmail}
                     onChange={event => setCompanyEmail(event.target.value)}/>
            </div>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <div className={classes.SecondLayer}>
              <FiGlobe/>
              <input placeholder="Company Website" value={companyWebSite}
                     onChange={event => setCompanyWebsite(event.target.value)}/>
            </div>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <div className={classes.SecondLayer}>
              <PhoneInput
                country={getCountry()}
                countryCodeEditable={false}
                value={contactNumber}
                onChange={handlePhoneChange}
                enableSearch={true}
                disableSearchIcon={true}
                inputClass={classes.PhoneInput}
                buttonClass={classes.ButtonClass}
                dropdownClass={classes.DropdownClass}
                searchClass={classes.SearchClass}
              />
            </div>
          </div>
        </div>


        <div className={classes.InputFields}>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <div className={classes.SecondLayer}>
              <FaWhatsapp/>
              <PhoneInput
                country={getCountry()}
                countryCodeEditable={false}
                value={whatsapp}
                onChange={handleWhatsappChange}
                enableSearch={true}
                disableSearchIcon={true}
                inputClass={classes.PhoneInput}
                buttonClass={classes.ButtonClass}
                dropdownClass={classes.DropdownClass}
                searchClass={classes.SearchClass}
              />
              <PrivacyModeDropdown
                color={props.color}
                visibility={whatsappVisibility ? 'PUBLIC' : 'PRIVATE'}
                onChangeVisibility={(e) => {
                  if (e === 'PUBLIC') {
                    setWhatsappVisibility(true);
                  } else {
                    setWhatsappVisibility(false);
                  }
                }}
              />
            </div>
          </div>

          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <Tooltip placement={"topRight"} title={"Facebook Profile Link or Username"}>
              <div className={classes.SecondLayer}>
                <FaFacebook/>
                <input placeholder="Facebook" value={facebook}
                       onChange={(e) => {
                         if (e.nativeEvent.inputType === 'deleteContentBackward') {
                           setFacebook(e.target.value)
                         } else {
                           const value = e.target.value.trim();
                           const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                           const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                           if (isBackspaceKeyPressed && !value) {
                             setFacebook('');
                           } else {
                             let updatedValue = value;

                             if (!isCompleteURL) {
                               updatedValue = `https://www.facebook.com/${value}`;
                             }

                             setFacebook(updatedValue);
                           }
                         }
                       }}
                />
                <PrivacyModeDropdown
                  color={props.color}
                  visibility={facebookVisibility ? 'PUBLIC' : 'PRIVATE'}
                  onChangeVisibility={(e) => {
                    if (e === 'PUBLIC') {
                      setFacebookVisibility(true);
                    } else {
                      setFacebookVisibility(false);
                    }
                  }
                  }
                />
              </div>
            </Tooltip>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <Tooltip placement={"topRight"} title={"Instagram Profile Link or Username"}>
              <div className={classes.SecondLayer}>
                <FaInstagram/>
                <input
                  placeholder="Instagram" value={instagram}
                  onChange={(e) => {
                    if (e.nativeEvent.inputType === 'deleteContentBackward') {
                      setInstagram(e.target.value)
                    } else {
                      const value = e.target.value.trim();
                      const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                      const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                      if (isBackspaceKeyPressed && !value) {
                        setInstagram('');
                      } else {
                        let updatedValue = value;

                        if (!isCompleteURL) {
                          updatedValue = `https://www.instagram.com/${value}`;
                        }

                        setInstagram(updatedValue);
                      }
                    }
                  }}
                />
                <PrivacyModeDropdown
                  color={props.color}
                  visibility={instagramVisibility ? 'PUBLIC' : 'PRIVATE'}
                  onChangeVisibility={(e) => {
                    if (e === 'PUBLIC') {
                      setInstagramVisibility(true);
                    } else {
                      setInstagramVisibility(false);
                    }
                  }
                  }
                />
              </div>
            </Tooltip>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <Tooltip placement={"topRight"} title={"Linkedin Profile Link or Username"}>
              <div className={classes.SecondLayer}>
                <FaLinkedin/>
                <input
                  placeholder="Linkedin"
                  value={linkedin}
                  onChange={(e) => {
                    if (e.nativeEvent.inputType === 'deleteContentBackward') {
                      setLinkedin(e.target.value)
                    } else {
                      const value = e.target.value.trim();
                      const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                      const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                      if (isBackspaceKeyPressed && !value) {
                        setLinkedin('');
                      } else {
                        let updatedValue = value;

                        if (!isCompleteURL) {
                          updatedValue = `https://www.linkedin.com/in/${value}`;
                        }

                        setLinkedin(updatedValue);
                      }
                    }
                  }}
                />
                <PrivacyModeDropdown
                  color={props.color}
                  visibility={linkedinVisibility ? 'PUBLIC' : 'PRIVATE'}
                  onChangeVisibility={(e) => {
                    if (e === 'PUBLIC') {
                      setLinkedinVisibility(true);
                    } else {
                      setLinkedinVisibility(false);
                    }
                  }
                  }
                />
              </div>
            </Tooltip>
          </div>
          <div
            className={classes.InputWrapper}
            style={{
              background: `linear-gradient(142deg, 
              ${createGradient(props.color, 40)} 0%, 
              ${adjustColorBrightness(createGradient(props.color, 50), 70)} 100%)`
            }}
          >
            <Tooltip placement={"topRight"} title={"Twitter Profile Link or Username"}>
              <div className={classes.SecondLayer}>
                <FaTwitter/>
                <input
                  placeholder="Twitter"
                  value={twitter}
                  onChange={(e) => {
                    if (e.nativeEvent.inputType === 'deleteContentBackward') {
                      setTwitter(e.target.value)
                    } else {
                      const value = e.target.value.trim();
                      const isCompleteURL = value.startsWith('http://') || value.startsWith('https://');

                      const isBackspaceKeyPressed = e.nativeEvent.inputType === 'deleteContentBackward';
                      if (isBackspaceKeyPressed && !value) {
                        setTwitter('');
                      } else {
                        let updatedValue = value;

                        if (!isCompleteURL) {
                          updatedValue = `https://www.twitter.com/${value}`;
                        }

                        setTwitter(updatedValue);
                      }
                    }
                  }}
                />
                <PrivacyModeDropdown
                  color={props.color}
                  visibility={twitterVisibility ? 'PUBLIC' : 'PRIVATE'}
                  onChangeVisibility={(e) => {
                    if (e === 'PUBLIC') {
                      setTwitterVisibility(true);
                    } else {
                      setTwitterVisibility(false);
                    }
                  }
                  }
                />
              </div>
            </Tooltip>
          </div>
          <div className={classes.ButtonContainer}>
            <button
              style={{
                background: `linear-gradient(91.3deg, 
                    ${props.color} 1.61%, 
                    ${adjustColorBrightness(props.color, 70)} 100%)`
              }}
              onClick={() => setUpdateConfirmPopupVisible(true)}
            >
              {props.loading === true ? (
                <div className={classes.SpinnerContainer}>
                  <ClipLoader color={'#ffffff'} loading={true} size={17}/>
                </div>
              ) : (
                <p>Update Details</p>
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => {
  return {
    color: state.home.status.themeColor,
    profilePicture: state.profile.profilePicture,
    loading: state.teams.loading,
  };
};

const matDispatchToProps = dispatch => {
  return {
    // onChangeProfilePicture: image =>
    //   dispatch(actions.changeProfilePicture(image)),
    onGetCompanyDetails: () => dispatch(getTeamsCompanyDetails()),
    onUpdateCompanyDetails: (data, showToast) => dispatch(updateTeamsCompanyDetails(data, showToast)),
    onUploadCompanyLogo: logo => dispatch(uploadCompanyLogo(logo)),
  };
};

export default connect(mapStateToProps, matDispatchToProps)(CompanyDetails);

