import classes from "./ContactEditPopup.module.scss";
import React from "react";
import {MdOutlineClose} from "react-icons/md";
import * as actions from "store/actions";
import {connect} from "react-redux";
import {ClipLoader} from "react-spinners";
import {isValidPhoneNumber} from "libphonenumber-js";
import {showToast} from "common/utils";

const ContactEditPopup = props => {
  const [name, setName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [note, setNote] = React.useState('');

  React.useEffect(() => {
    setName(props.contactDetails.name);
    setCompany(props.contactDetails.company);
    setTitle(props.contactDetails.title);
    setContactNumber(props.contactDetails.contactNo);
    setEmail(props.contactDetails.email);
    setNote(props.contactDetails.note);
  }, [])

  const onUpdateHandler = () => {
    if (formIsValid()) {
      props.onUpdateContact({
        company,
        contactId: props.contactDetails.id,
        contactNo: contactNumber,
        email,
        name,
        note,
        receivedProfile: props.activeProfileId,
        title,
      })

      props.onClose();
    }
  };

  const formIsValid = () => {
    if (name.trim() === '') {
      showToast('Name cannot be empty', props.color);
      return false;
    }
    if (contactNumber.trim() === '') {
      showToast('Contact number cannot be empty', props.color);
      return false;
    }

    if (!isValidPhoneNumber(contactNumber)) {
      showToast('Invalid phone number', props.color);
      return false;
    }

    if (email.trim() !== '') {
      const atposition = email.indexOf('@');
      const dotposition = email.lastIndexOf('.');

      if (
        atposition === -1 ||
        dotposition === -1 ||
        atposition < 1 ||
        dotposition < atposition + 2 ||
        dotposition + 2 >= email.length
      ) {
        showToast('Invalid email', props.color);
        return false;
      }
    }
    return true;
  };



  return (
    <div className={classes.ContactEditPopup}>

      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>

      <div className={classes.DetailContainer}>
        <div className={classes.Detail}>
          <p>Name:</p>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={classes.Detail}>
          <p>Contact No:</p>
          <input
            placeholder="Number"
            value={contactNumber}
            onChange={e => setContactNumber(e.target.value.replace(/[^0-9+]/g, ""))}
          />
        </div>
        <div className={classes.Detail}>
          <p>Email:</p>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={classes.Detail}>
          <p>Title:</p>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <div className={classes.Detail}>
          <p>Company:</p>
          <input
            type="text"
            placeholder="Company"
            value={company}
            onChange={e => setCompany(e.target.value)}
          />
        </div>
        <div className={classes.Detail}>
          <p>Notes:</p>
          <input
            type="text"
            placeholder="Notes"
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.ButtonContainer}>
        <button onClick={() => {
          onUpdateHandler();
        }}>
          {props.loading === true ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader color={props.color} loading={true} size={22}/>
            </div>
          ) : (
            <p style={{color: props.color}}>Save</p>
          )
          }
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.contact.loading,
    user: state.auth.user,
    profiles: state.home.profiles,
      color: state.home.status.themeColor,
    activeProfileId: state.home.status.activeProfileId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateContact: contactDetails => dispatch(actions.updateContact(contactDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditPopup);
