import React, {useEffect, useState} from "react";
import classes from "./ContactDetailPopup.module.scss";
import {MdDone, MdOutlineClose} from "react-icons/md";
import * as actions from "store/actions";
import {connect} from "react-redux";
import {ClipLoader} from "react-spinners";
import Backdrop from "components/UI/Backdrop/Backdrop";
import ContactDeleteConfirmPopup from "../ContactDeleteConfirmPopup/ContactDeleteConfirmPopup";
import ContactEditPopup from "../ContactEditPopup/ContactEditPopup";
import {createGradient} from "shared/constants";
import VCard from "vcard-creator";
import {isMobile} from 'react-device-detect';
import {showToast} from "../../../../../common/utils";

const ContactDetailPopup = (props) => {

  const [contactDetails, setContactDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmationPopupVisible, setDeleteConfirmationPopupVisible] = useState(false);
  const [toggleDeleteButton, setToggleDeleteButton] = useState(false);
  const [editPopupVisible, setEditPopupVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await props.onGetContact(props.contactId);
        if (data?.data) {
          setContactDetails(data.data);
        }
      } catch (e) {
        console.log(e);
        showToast("Something went wrong, please try again", props.themeColor);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  const onDeleteHandler = async () => {
    try {
      await props.onDeleteContact(props.contactId);
      showToast("Contact deleted successfully!", props.themeColor);
      props.onClose();

    } catch (err) {
      showToast("Something went wrong!", props.themeColor);
    }
  }


  const handleSaveContact = () => {
    const myVCard = new VCard();

    myVCard
        .addName(contactDetails.name)
        .addPhoneNumber(contactDetails.contactNo, "CELL");

    if (contactDetails.title) myVCard.addJobtitle(contactDetails.title);
    if (contactDetails.company) myVCard.addCompany(contactDetails.company);
    if (contactDetails.email) myVCard.addEmail(contactDetails.email, "Email");

    const data = myVCard.buildVCard();
    let url = "data:text/x-vcard;charset=utf-8," + encodeURIComponent(data);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contactDetails.name}.vcf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  return (
      <div className={classes.ContactDetailPopup}>
        <div className={classes.CloseButtonContainer}>
          <div onClick={props.onClose} style={{cursor: "pointer"}}>
            <MdOutlineClose size={22} color="#C5C5C5"/>
          </div>
        </div>
        {deleteConfirmationPopupVisible ? (
            <Backdrop onClick={() => setDeleteConfirmationPopupVisible(false)}>
              <ContactDeleteConfirmPopup
                  contactId={props.contactId}
                  onClose={() => {
                    setDeleteConfirmationPopupVisible(false)
                    props.onClose();
                  }}
              />
            </Backdrop>
        ) : null}
        {editPopupVisible ? (
            <Backdrop onClick={() => setEditPopupVisible(false)}>
              <ContactEditPopup
                  contactDetails={contactDetails}
                  onClose={() => {
                    setEditPopupVisible(false)
                    props.onClose();
                  }}
              />
            </Backdrop>
        ) : null}
        {isLoading ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader color={props.themeColor} loading={true} size={48}/>
            </div>
        ) : (
            <>
              <div className={classes.PopupHeader}>
                <div className={classes.NameInitials}>
                  <p style={{color: props.themeColor}}>
                    {`${contactDetails.name?.split(' ').reduce((acc, val) => acc + val.substring(0, 1).toUpperCase(), '').substring(0, 2)}`}
                  </p>
                </div>
                <p style={{color: props.themeColor}} className={classes.Name}>{contactDetails.name}</p>
              </div>

              <div className={classes.DetailContainer}>
                <div className={classes.Detail}>
                  <p>Contact No:</p>
                  <p>{contactDetails.contactNo === null ? '-' : contactDetails.contactNo}</p>
                </div>
                <div className={classes.Detail}>
                  <p>Email:</p>
                  <p>{contactDetails.email === null || contactDetails.email === '' ? '-' : contactDetails.email}</p>
                </div>
                <div className={classes.Detail}>
                  <p>Title:</p>
                  <p>{contactDetails.title === null || contactDetails.title === '' ? '-' : contactDetails.title}</p>
                </div>
                <div className={classes.Detail}>
                  <p>Company:</p>
                  <p>{contactDetails.company === null || contactDetails.company === '' ? '-' : contactDetails.company}</p>
                </div>
                <div className={classes.Detail}>
                  <p>Notes:</p>
                  <p>{contactDetails.note === null || contactDetails.note === '' ? '-' : contactDetails.note}</p>
                </div>
                {/*   <div className={classes.Detail}>
              <p>Saved from:</p>
              <p>Business Card {contactDetails.receivedProfile === null ? '-' : contactDetails.receivedProfile}</p>
            </div>*/}
              </div>
            </>
        )
        }

        <div className={classes.ButtonContainer}>
          <button
              style={{color: props.themeColor}}
              onClick={() => setEditPopupVisible(true)}
          >
            Edit
          </button>
          {!toggleDeleteButton ? (
              <button onClick={() => {
                setToggleDeleteButton(true)
              }}>
                Delete
              </button>
          ) : (
              <div className={classes.DeleteConfirmation}>
                <div onClick={onDeleteHandler}>
                  <MdDone color="#F3F3F3"/>
                </div>
                <div onClick={() => setToggleDeleteButton(false)}>
                  <MdOutlineClose color="#363636"/>
                </div>
              </div>
          )}
        </div>

        {isMobile && (
            <button
                style={{
                  background: `linear-gradient(91.3deg, 
            ${props.themeColor} 
            1.61%, 
            ${createGradient(props.themeColor, 50)} 
            100%)`
                }}
                className={classes.SaveToPhoneBtn}
                onClick={handleSaveContact}
            >
              Save to Phone
            </button>
        )}

      </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.contact.loading,
    themeColor: state.home.status.themeColor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetContact: (contactId) => dispatch(actions.getContact(contactId)),
    onDeleteContact: (contactId) => dispatch(actions.deleteContact(contactId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailPopup);
