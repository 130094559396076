import React, {useEffect, useState} from 'react';
import classes from './RegisterForm.module.scss';
import Input from '../../../UI/Input/Input';
import {RiUser3Line, RiUserLocationLine} from 'react-icons/ri';
import {MdOutlineEmail, MdOutlineGeneratingTokens} from 'react-icons/md';
import {useNavigate, useSearchParams} from 'react-router-dom';
import validator from 'validator';
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import ct from 'countries-and-timezones';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import {AiFillCloseCircle} from "react-icons/ai";
import {adjustColorBrightness, createGradient} from "../../../../shared/constants";
import {showToast} from "../../../../common/utils";

const RegisterForm = props => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const color = searchParams.get('color') !== 'null' ? `#${searchParams.get('color')}` : '#DBB659';
  const adjustColor = adjustColorBrightness(color, 65);
  const defaultGradient = "linear-gradient(142deg, rgba(132, 111, 59, 1) 0%, rgba(53, 47, 32, 1) 100%)";
  const gradient = color === '#DBB659' || color === "#null" ? defaultGradient : `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor, 60)} 100%)`;

  const tenantName = "CommercialPrime";
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactNo, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [registrationToken, setRegistrationToken] = useState(token || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accountType, setAccountType] = useState('PERSONAL');
  const [companyId, setCompanyId] = useState(null);
  const [isUserNameAvailable, setIsUserNameAvailable] = useState(false);
  const [isTokenDisabled, setIsTokenDisabled] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [tokenDataLoading, setTokenDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz);
    return obj.id.toLowerCase();
  };

  const updateFormDataUsingToken = (token) => {
    props.fetchUserDetailsByToken(token)
      .then(data => {
        if (data) {
          if (!firstName) setFirstName(data.firstName || '');
          if (!lastName) setLastName(data.lastName || '');
          if (!contactNo) setContact(data.contactNum || '');
          if (!email) setEmail(data.email || '');
          setIsTokenDisabled(true);
          setIsEmailDisabled(true);
          setAccountType(data.accountType);
          setCompanyId(data.companyId)
        }
      })
      .finally(() => setTokenDataLoading(false));
  }

  useEffect(() => {
    if (token === null) {
      setIsTokenDisabled(false);
    } else {
      setIsTokenDisabled(true);
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.onIsUserNameExist(username);
        if (username.length < 4) {
          setIsUserNameAvailable(true);
        } else {
          setIsUserNameAvailable(response);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [username, props.onIsUserNameExist]);

  useEffect(() => {
    if (token) {
      setTokenDataLoading(true);
      updateFormDataUsingToken(token);
    }
  }, []);


  const handleTokenChange = event => {
    const value = event.target.value.replace(/[^a-zA-Z0-9-_]+/g, '');
    setRegistrationToken(value);
    if (value.length > 10) {
      setTokenDataLoading(true);
      updateFormDataUsingToken(value);
    }
  };

  const onRegisterHandler = () => {
    if (formIsValid()) {
      setSubmitLoading(true);
      props.onRegister(
        firstName,
        accountType,
        lastName,
        companyId,
        contactNo,
        email,
        password,
        registrationToken,
        tenantName,
        username,
        successCallback,
        errorCallback,
        showToast,
      );
    }
  };

  const errorCallback = () => {
    setSubmitLoading(false);
  };

  const successCallback = () => {
    setSubmitLoading(false);
    showToast('User registration successful, please login!');
    navigate('/');
  };

  const formIsValid = () => {
    const usernameRegex = /^(?!\s)[\w\s]+$/;
    const nameRegex = /^[a-zA-Z\s']+$/;

    if (username === '') {
      showToast('Username cannot be empty');
    } else if (!username.match(usernameRegex)) {
      showToast('Username can only contain letters and numbers');
    } else if (registrationToken === '') {
      showToast('Registration token cannot be empty');
    } else if (firstName === '') {
      showToast('First name cannot be empty');
    } else if (!firstName.match(nameRegex)) {
      showToast('First name is invalid');
    } else if (email === '') {
      showToast('Email cannot be empty');
    } else if (!validator.isEmail(email)) {
      showToast('Invalid email address');
    } else if (lastName === '') {
      showToast('Last name cannot be empty');
    } else if (!lastName.match(nameRegex)) {
      showToast('Last name is invalid');
    } else if (contactNo === '') {
      showToast('Contact number cannot be empty');
    } else if (!isValidPhoneNumber(contactNo)) {
      showToast('Invalid phone number');
    } else if (password === '') {
      showToast('Password cannot be empty');
    } else if (confirmPassword === '') {
      showToast('Confirm password cannot be empty');
    } else if (password !== confirmPassword) {
      showToast('Passwords do not match');
    } else {
      return true;
    }

    return false;
  };

  const handlePhoneChange = phone => {
    setContact('+' + phone);
  };

  return (
    <div className={classes.RegisterFormContainer}>
      <div className={classes.RegisterForm}>
        <div className={classes.RegisterCards}>
          <div>
            <div className={classes.Input} style={{background: gradient}}>
              <div className={classes.IconContainer}><RiUser3Line/></div>
              <input
                type={'text'}
                placeholder="Username"
                onChange={event => setUsername(event.target.value.replace(/[^a-zA-Z0-9]+/g, ''))}
                value={username}
              />
              <div className={classes.TickIcon}>
                {username.length === 0 ? null :
                  (isUserNameAvailable ?
                    <AiFillCloseCircle color={'#ff3b3b'}/>
                    : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                }
              </div>
            </div>

            <Input
              type="text"
              placeholder="First name"
              icon={<RiUser3Line/>}
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
              style={gradient}
            />
            <Input
              type="text"
              placeholder="Last name"
              value={lastName}
              icon={<RiUserLocationLine/>}
              onChange={event => setLastName(event.target.value)}
              style={gradient}
            />
            <div className={classes.Input} style={{background: gradient}}>
              <PhoneInput
                country={getCountry()}
                countryCodeEditable={false}
                value={contactNo}
                onChange={handlePhoneChange}
                enableSearch={true}
                disableSearchIcon={true}
                inputClass={classes.PhoneInput}
                buttonClass={classes.ButtonClass}
                dropdownClass={classes.DropdownClass}
                searchClass={classes.SearchClass}
              />
            </div>

          </div>
          <div>
            <Input
              type="text"
              placeholder="Registration Token"
              value={registrationToken}
              icon={<MdOutlineGeneratingTokens/>}
              onChange={handleTokenChange}
              style={gradient}
              isDisabled={isTokenDisabled}
            />
            <Input
              type="text"
              placeholder="Email"
              value={email}
              icon={<MdOutlineEmail/>}
              onChange={event => setEmail(event.target.value)}
              isDisabled={isEmailDisabled}
              style={gradient}
            />
            <Input
              type="password"
              placeholder="Password"
              icon={<MdOutlineGeneratingTokens/>}
              value={password}
              onChange={event => setPassword(event.target.value)}
              style={gradient}
            />
            <Input
              type="password"
              placeholder="Confirm Password"
              icon={<MdOutlineGeneratingTokens/>}
              value={confirmPassword}
              onChange={event => setConfirmPassword(event.target.value)}
              style={gradient}
            />
          </div>
        </div>

        {props.loading || submitLoading ? (
          <div className={classes.SpinnerContainer}>
            <ClipLoader color={'#dbb659'} loading={true} size={20}/>
          </div>
        ) : tokenDataLoading ? <div className={classes.SpinnerContainer}>
          <ClipLoader color={'#dbb659'} loading={true} size={20}/> <span
          style={{color: 'white', fontSize: 'small'}}>Loading data...</span>
        </div> : (

          <button
            className={classes.RegisterButton}
            onClick={onRegisterHandler}
            style={{
              background: `linear-gradient(142deg, ${color} 0%, ${createGradient(color, 50)} 100%)`
            }}
          >
            Register
          </button>
        )}

        <div
          className={classes.AlreadyHaveAccount}
          onClick={() => navigate('/')}
        >
          <p>
            Already have an account? <span style={{color: color}}>Log In</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: (
      firstName,
      type,
      lastName,
      companyId,
      contactNo,
      email,
      password,
      token,
      tenant,
      username,
      successCallback,
      errorCallback,
      showToast
    ) => dispatch(
      actions.register(
        firstName,
        type,
        lastName,
        companyId,
        contactNo,
        email,
        password,
        token,
        tenant,
        username,
        successCallback,
        errorCallback,
        showToast
      )
    ),
    onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
    fetchUserDetailsByToken: token => dispatch(actions.fetchUserDetailsByToken(token))
  };
};

export default connect(null, mapDispatchToProps)(RegisterForm);
