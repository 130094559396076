import classes from "./CompanyUpdateConfirmation.module.scss";
import {MdOutlineClose} from "react-icons/md";
import React from "react";
import {createGradient} from "shared/constants";

const CompanyUpdateConfirmation = props =>{
  return(
    <div className={classes.CompanyDetailUpdate}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Update Confirmation
      </p>
      <p className={classes.InfoText}>
        Do You want to Update Company Details?
      </p>
      <button style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
              className={classes.Button}
              onClick={props.onUpdate}
      >
        Yes, Update
      </button>
    </div>
  )
}

export default CompanyUpdateConfirmation;
