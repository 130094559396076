import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import classes from './Home.module.scss';
import ProfileCard from './ProfileCard/ProfileCard';
import * as actions from '../../../../store/actions';
import {changePrivacyMode} from '../../../../store/actions';
import {connect} from 'react-redux';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import StatusChangeConfirmation from './StatusChangeConfirmation/StatusChangeConfirmation';
import PreviewConfirmation from './PreviewConfirmation/PreviewConfirmation';
import {ClipLoader} from 'react-spinners';
import {HiQrcode} from 'react-icons/hi';
import QRCodePopup from './QRCodePopup/QRCodePopup';
import ProfileLimitReached from '../../../UI/ProfileLimitReached/ProfileLimitReached';
import {Spin, Switch} from 'antd';
import PrivacyMode from '../../../../assets/images/highPrivacy.png';
import PrivacyModeChangeConfirm from '../../../UI/PrivacyModeChangeConfirm/PrivacyModeChangeConfirm';
import {PROFILE_STATUS, ROUTES_PATHS} from '../../../../common/enums';
import {showToast} from '../../../../common/utils';
import {BsPlus} from "react-icons/bs";

const Home = props => {
  const navigate = useNavigate();

  const [activationStatusPopupVisible, setActivationStatusPopupVisibility] =
    useState(false);

  const [qrCodePopupVisible, setQrCodePopupVisibility] = useState(false);

  const [profileLimitReachedPopupVisible, setProfileLimitReachedPopupVisible] =
    useState(false);

  const [privacyModePopupVisible, setPrivacyModePopupVisibility] =
    useState(false);

  const [previewConfirmationVisible, setPreviewConfirmationVisibility] =
    useState(false);

  const [profileId, setProfileId] = useState(null);

  useEffect(() => {
    if (props.profiles?.length === 0 || props.products?.length === 0) {
      props.getStatus();
      props.getLinkedProductsByUsername(props.user);
      props.getProfiles();
    }
    console.log(props.profiles);
  }, []);

  const onChangeActiveProfileHandler = (profileId, callback = () => {}) => {
    if (props.status.activeProfileId !== profileId) {
      try {
        props.onChangeActiveProfile(profileId);
        showToast('Active profile changed successfully!', props.themeColor);
        callback();
      } catch (err) {
        showToast('Failed to change active profile', props.themeColor);
      }
    }
  };

  const openActiveProfile = () => {
    if (props.products.length > 0) {
      window.open(
        process.env.REACT_APP_SHAREABLE_VIEW_URL + '@' + props.products[0].link,
        '_blank',
      );
    }
  };

  const handleSwitchClick = () => {
    props.onChangeHighPrivacyMode(!props.status.highPrivacyMode);
    setPrivacyModePopupVisibility(false);
  };

  const onConfirmationHandler = () => {
    setPreviewConfirmationVisibility(false);
    onChangeActiveProfileHandler(profileId, () => {
      setTimeout(openActiveProfile, 500);
    });
  };

  return (
    <div className={classes.DashboardContent}>
      {props.isStatusDataLoading && (
        <Spin
          tip="Loading"
          size={'large'}
          style={{color: props.themeColor}}
          fullscreen
        />
      )}
      {activationStatusPopupVisible && (
        <Backdrop onClick={() => setActivationStatusPopupVisibility(false)}>
          <StatusChangeConfirmation
            themeColor={props.themeColor}
            onClose={() => setActivationStatusPopupVisibility(false)}
          />
        </Backdrop>
      )}

      {privacyModePopupVisible && (
        <Backdrop onClick={() => setPrivacyModePopupVisibility(false)}>
          <PrivacyModeChangeConfirm
            onClose={() => setPrivacyModePopupVisibility(false)}
            themeColor={props.themeColor}
            active={props.status.highPrivacyMode}
            onClick={handleSwitchClick}
          />
        </Backdrop>
      )}

      {previewConfirmationVisible && (
        <Backdrop onClick={() => setPreviewConfirmationVisibility(false)}>
          <PreviewConfirmation
            themeColor={props.themeColor}
            onClose={() => setPreviewConfirmationVisibility(false)}
            onConfirmation={onConfirmationHandler}
          />
        </Backdrop>
      )}

      {profileLimitReachedPopupVisible && (
        <Backdrop onClick={() => setProfileLimitReachedPopupVisible(false)}>
          <ProfileLimitReached
            themeColor={props.themeColor}
            onClose={() => setProfileLimitReachedPopupVisible(false)}
            //onConfirmation={onConfirmationHandler}
          />
        </Backdrop>
      )}

      {qrCodePopupVisible && (
        <Backdrop onClick={() => setQrCodePopupVisibility(false)}>
          <QRCodePopup
            onClose={() => setQrCodePopupVisibility(false)}
            themeColor={props.themeColor}
          />
        </Backdrop>
      )}

      <div className={classes.ContentHeader}>
        <div>
          <p className={classes.Greeting}>
            Hello, <span style={{color: props.themeColor}}>{props.user}!</span>
          </p>
          {!props.isStatusDataLoading &&
            (props.isProfileStatusLoading ? (
              <div className={classes.SpinnerContainer}>
                <ClipLoader color={props.themeColor} loading={true} size={24} />
              </div>
            ) : (
              <p
                className={
                  props.status?.profileStatus === PROFILE_STATUS.ACTIVE
                    ? classes.AccountStatusActive
                    : classes.AccountStatusInactive
                }
                onClick={() => setActivationStatusPopupVisibility(true)}
              >
                {props.status?.profileStatus === PROFILE_STATUS.ACTIVE
                  ? 'Card Active'
                  : 'Card Inactive'}
              </p>
            ))}
        </div>
        <div>
          {!props.isStatusDataLoading && props.status?.hpmEnabledForTenant && (
            <div className={classes.HighPrivacySwitch}>
              <img src={PrivacyMode} alt={'privacy mode'} width={36} />
              <p>
                High Privacy
                <br />
                Mode
              </p>
              <Switch
                loading={props.isHpmStatusLoading}
                checked={props.status.highPrivacyMode}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                // onClick={handleSwitchClick}
                onClick={() => setPrivacyModePopupVisibility(true)}
              />
            </div>
          )}

          <HiQrcode
            className={classes.QrButton}
            onClick={() => setQrCodePopupVisibility(true)}
          />
          <button
            disabled={props.isProfileDataLoading}
            className={classes.CreateProfileButton}
            onClick={() => {
              if (props.status?.profileLimit > props.profiles.length) {
                navigate(`/${ROUTES_PATHS.USER.CREATE_PROFILE}`);
              } else {
                setProfileLimitReachedPopupVisible(true);
              }
            }}
          >
            <BsPlus size={20}/>
            &nbsp;Create Profile
          </button>
        </div>
      </div>
      <div className={classes.Content}>
        {!props.isProfileDataLoading && props.profiles?.length === 0 && (
          <h4
            style={{
              color: 'white',
              textAlign: 'center',
              margin: 'auto',
              padding: 32,
              fontWeight: 'normal',
            }}
          >
            No Profiles Found
          </h4>
        )}

        {!props.isProfileDataLoading &&
          props.profiles?.length > 0 &&
          props.profiles?.map(profile => {
            const isProfileActive = profile.id === props.status.activeProfileId;
            return (
              <ProfileCard
                showConfirmation={id => {
                  if (isProfileActive) {
                    openActiveProfile();
                  } else {
                    setPreviewConfirmationVisibility(true);
                    setProfileId(id);
                  }
                }}
                profile={profile}
                isActive={isProfileActive}
                key={profile.id}
                onChangeActiveProfileHandler={onChangeActiveProfileHandler}
              />
            );
          })}

        <button
          className={classes.CreateNewProfileButtonMobile}
          onClick={() => {
            if (props.status.profileLimit > props.profiles?.length) {
              navigate(ROUTES_PATHS.USER.CREATE_PROFILE);
            } else {
              setProfileLimitReachedPopupVisible(true);
            }
          }}
        >
          Create New Profile
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isStatusDataLoading: state.home.isStatusDataLoading,
    isProfileDataLoading: state.home.isProfileDataLoading,
    isProfileStatusLoading: state.home.isProfileStatusLoading,
    isHpmStatusLoading: state.home.isHpmStatusLoading,
    profiles: state.home.profiles,
    status: state.home.status,
    themeColor: state.home.status.themeColor,
    role: state.auth.userRole,
    products: state.products.products,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStatus: () => dispatch(actions.getStatus()),
    getProfiles: () => dispatch(actions.getAllProfiles()),
    onChangeActiveProfile: profileId =>
      dispatch(actions.changeActiveProfile(profileId)),
    getLinkedProductsByUsername: userName =>
      dispatch(actions.getLinkedProductsByUsername(userName)),
    onChangeHighPrivacyMode: value => dispatch(changePrivacyMode(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
