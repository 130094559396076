import React, {useState} from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import {CgMenuLeft, CgMenuRight} from 'react-icons/cg';
import {BiArrowBack, BiBuildings} from 'react-icons/bi';
import {FiUsers} from 'react-icons/fi';
import {BsFillCreditCardFill} from 'react-icons/bs';
import {MdOutlineBrandingWatermark, MdOutlineSettings} from 'react-icons/md';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES_PATHS} from '../../../../../../common/enums';
import classes from './SideDrawer.module.scss';

const SideDrawer = ({color}) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleDrawer = () => setIsOpen(!isOpen);

    const menuItems = [
        {path: ROUTES_PATHS.COMPANY_ADMIN.USERS, label: 'Users', icon: <FiUsers/>},
        {path: ROUTES_PATHS.COMPANY_ADMIN.PROFILES, label: 'Profiles', icon: <BsFillCreditCardFill/>},
        {path: ROUTES_PATHS.COMPANY_ADMIN.COMPANY_DETAILS, label: 'Company Details', icon: <BiBuildings/>},
        {path: ROUTES_PATHS.COMPANY_ADMIN.BRANDING, label: 'Branding', icon: <MdOutlineBrandingWatermark/>},
        {path: ROUTES_PATHS.COMPANY_ADMIN.SETTINGS, label: 'Settings', icon: <MdOutlineSettings/>},
    ];

    const renderMenuItems = menuItems.map(({path, label, icon}) => {
        const isActive = location.pathname.includes(`${ROUTES_PATHS.COMPANY_ADMIN.TEAMS}/${path}`);
        return (
            <div
                key={path}
                className={isActive ? classes.DrawerItemSelected : classes.DrawerItemNotSelected}
                style={{background: isActive ? color : ''}}
                onClick={() => {
                    navigate(path);
                    toggleDrawer();
                }}
            >
                {icon}
                <span>{label}</span>
            </div>
        );
    });

    return (
        <div style={{display: location.pathname === ROUTES_PATHS.COMPANY_ADMIN.CREATE_PROFILE ? 'none' : null}}>
            <button onClick={toggleDrawer} className={classes.Button}>
                {isOpen ? <CgMenuRight/> : <CgMenuLeft/>}
            </button>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className={classes.DrawerContainer}
            >
                <div className={classes.Drawer}>
                    <div className={classes.DrawerTitle}>
                        <h1>TEAMS</h1>
                        <BiArrowBack onClick={toggleDrawer}/>
                    </div>
                    {renderMenuItems}
                </div>
            </Drawer>
        </div>
    );
};

export default SideDrawer;
