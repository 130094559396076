import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import classes from './CompanyDetailsNew.module.scss';
import commonClasses from '../Wrapper/Wrapper.module.scss';

import AddFieldModal from 'components/CreateProfile/AddFieldModal/AddFieldModal';
import {useNavigate} from 'react-router-dom';
import {adjustColorBrightness, createGradient} from 'shared/constants';
import * as actions from '../../../../../../store/actions';
import {getTeamsCompanyDetails, updateTeamsCompanyDetails, uploadCompanyLogo} from '../../../../../../store/actions';

import {base64ToFile, getPreSignedTempUrl, showToast, validateFields} from 'common/utils';
import {MdOutlineClose} from 'react-icons/md';
import {Spin} from 'antd';
import {COMPANY_DATA_FIELDS, PROFILE_DATA_PRIVACY_MODE} from 'common/enums';
import {useDropzone} from 'react-dropzone';
import {IoMdCloudUpload} from 'react-icons/io';
import imageCompression from 'browser-image-compression';
import {IoImageOutline} from 'react-icons/io5';
import ImageCropper from 'components/CreateProfile/ImageCropper/ImageCropper';
import ChangeLayoutModal from 'components/CreateProfile/ChangeLayoutModal/ChangeLayoutModal';
import {renderField} from "../../../../../CreateProfile/CreateOrEditProfile";

const CompanyDetailsNew = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const navigate = useNavigate();
  const [adjustColor, setAdjustColor] = useState(null);
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);

  const [saveButtonIsClicked, setSaveButtonIsClicked] = useState(false);
  const [portalBrandingType, setPortalBrandingType] = useState(null);
  const [stage, setStage] = useState(null);
  const [status, setStatus] = useState(null);
  const [logo, setLogo] = useState(undefined);
  const [logoId, setLogoID] = useState(null);
  const [logoPreview, setLogoPreview] = useState(undefined);

  const [compressedImage, setCompressedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [showChangeLayoutModal, setShowChangeLayoutModal] = useState(false);

  const compressImage = async file => {
    if (file.type.split('/')[0] !== 'image') {
      showToast('Please select a valid image to compress', props.themeColor);
      return;
    }

    try {
      const compressedImage = await imageCompression(file, {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1280,
        useWebWorker: true,
      });

      const base64Result = await getBase64(compressedImage);
      setCompressedImage(base64Result);
      setShowCropper(true);
    } catch (error) {
      console.error('Image compression error: ', props.themeColor);
      showToast('Error compressing image', props.themeColor);
    }
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onDrop = useCallback(acceptedFiles => {
    compressImage(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const [companyFields, setCompanyFields] = useState(
    Object.entries(COMPANY_DATA_FIELDS).map(
      ([
        key,
        {name, icon, type, privacyMode, privacyModeToggleAllowed, layout},
      ]) => ({
        id: null,
        name: name || key,
        icon: icon || null,
        type: type || 'text',
        privacyMode: privacyMode || 'public',
        privacyModeToggleAllowed: privacyModeToggleAllowed || false,
        value: '',
        layout: layout || 'default',
      }),
    ),
  );

  useEffect(() => {
    setAdjustColor(adjustColorBrightness(props.themeColor, 60));
  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      console.log(props.companyDetails);
      if (!props.companyDetails) {
        setLoadingMessage('Loading Company Data');
        setIsLoading(true);
        try {
          const companyData = await props.onGetCompanyDetails();
          await processCompanyData(companyData);
        } catch (error) {
          console.error('Failed to load company data:', error);
          showToast('Failed to load company data', props.themeColor);
          setCompanyFields([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        await processCompanyData(props.companyDetails);
      }
    };

    const processCompanyData = async companyData => {
      const {portalBrandingType, stage, status, data: fields} = companyData;

      if (!Array.isArray(fields)) {
        console.error('Unexpected data format:', companyData);
        setCompanyFields([]);
        return;
      }

      const {logoField, filteredFields} = fields.reduce(
        (acc, field) => {
          if (field.name === 'Logo') {
            acc.logoField = field;
          } else {
            acc.filteredFields.push(field);
          }
          return acc;
        },
        {logoField: null, filteredFields: []},
      );

      setCompanyFields(filteredFields);
      setPortalBrandingType(portalBrandingType);
      setStage(stage);
      setStatus(status);

      if (logoField) {
        setLogoID(logoField.id);
        if (logoField.value) {
          let logoUrl = logoField.value;
          if (!logoUrl.includes('public')) {
            logoUrl = await getPreSignedTempUrl(logoUrl);
          }
          setLogoPreview(logoUrl);
        }
      }
    };

    fetchCompanyDetails();
  }, [props.companyDetails]);

  const leftCompanyFields = [
    COMPANY_DATA_FIELDS.NAME.name,
    COMPANY_DATA_FIELDS.ADDRESS.name,
    COMPANY_DATA_FIELDS.CONTACT_NUMBER.name,
    COMPANY_DATA_FIELDS.WEBSITE.name,
    COMPANY_DATA_FIELDS.EMAIL.name,
  ];

  //   useEffect(() => {
  //     const fetchCompanyDetails = async () => {
  //       setLoadingMessage('Loading Company Data');
  //       setIsLoading(true);
  //       try {
  //         const companyData = await props.onGetCompanyDetails();
  //         setCompanyFields(companyData);
  //       } catch (error) {
  //         showToast('Failed to load company data', props.themeColor);
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     };

  //     fetchCompanyDetails();
  //   }, []);

  const onUpdateProfileField = updatedField => {
    setCompanyFields(prevFields =>
      prevFields.map(field =>
        field.name === updatedField.name ? {...field, ...updatedField} : field,
      ),
    );
  };

  const onUpdateProfileFields = updatedFields => {
    setCompanyFields(prevFields =>
      prevFields.map(field => {
        const updatedField = updatedFields.find(f => f.name === field.name);
        return updatedField ? {...field, ...updatedField} : field;
      }),
    );
  };

  const onAddProfileField = data => {
    const updatedCompanyFields = [...companyFields];

    updatedCompanyFields.push();

    setCompanyFields([
      ...companyFields,
      {
        id: null,
        name: data.fieldName,
        icon: data.fieldIcon,
        type: data.fieldType,
        value: '',
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        isCustom: true,
      },
    ]);
  };

  const REQUIRED_FIELDS = [
    COMPANY_DATA_FIELDS.NAME.name,
    COMPANY_DATA_FIELDS.EMAIL.name,
  ];

  const onRemoveProfileField = fieldName => {
    setCompanyFields(companyFields.filter(field => field.name !== fieldName));
  };

  const renderProfilePictureForDesktop = () => (
    <div
      className={classes.DropArea}
      {...(!isReadOnly ? getRootProps() : [])}
      style={{
        borderColor: isDragActive ? '#dbb659' : '#424242',
        cursor: isReadOnly ? 'no-drop' : 'pointer',
      }}
    >
      {' '}
      {!isReadOnly && <input {...getInputProps()} accept="image/*" />}
      <div>
        <div className={classes.ImageContainer}>
          {logoPreview && !isReadOnly && (
            <div
              className={classes.ClearButton}
              onClick={e => {
                setCompressedImage(null);
                setLogo(null);
                setLogo(null);
                e.stopPropagation();
              }}
            >
              <MdOutlineClose size={18} color="#C5C5C5" />
            </div>
          )}
          {logoPreview ? (
            <img src={logoPreview} alt="Profile Picture" />
          ) : (
            <div className={classes.IconContainer}>
              <IoMdCloudUpload size={72} color="#919191" />
            </div>
          )}
        </div>

        {!isReadOnly && (
          <div style={{marginLeft: '32px'}}>
            <p>
              Drag & drop or click to select
              <br />
              your Company Logo
            </p>
          </div>
        )}
      </div>
    </div>
  );

  const renderProfilePictureForMobile = () => (
    <div className={classes.MobileProfilePictureContainer}>
      <div
        style={{
          background: `linear-gradient(91.3deg, ${adjustColor} 1.61%, ${createGradient(
            adjustColor,
            50,
          )} 100%)`,
        }}
        className={classes.ProfilePicture}
        {...getRootProps()}
      >
        <div>
          {logoPreview && !isReadOnly && (
            <div
              className={classes.ClearButton}
              onClick={e => {
                setCompressedImage(null);
                setLogo(null);
                setLogoPreview(null);
                e.stopPropagation();
              }}
            >
              <MdOutlineClose size={16} color="#C5C5C5" />
            </div>
          )}
          {logoPreview ? (
            <img src={logoPreview} alt="Profile" />
          ) : (
            <IoImageOutline size={32} color="#929292" />
          )}
        </div>
      </div>
      <p>Profile Picture</p>
    </div>
  );

  const uploadProfilePicture = async () => {
    let file;
    if (logo.includes(';base64')) {
      file = base64ToFile(logo, 'profile-picture');
    } else {
      file = logo;
    }

    try {
      const uploadResult = await props.onUploadFile(file);
      return uploadResult.data;
    } catch (error) {
      showToast('Failed to upload profile picture', props.themeColor);
      console.log('error', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveHandler = async () => {
    const errors = validateFields(companyFields, REQUIRED_FIELDS);

    if (errors.length > 0) {
      errors.forEach(error => showToast(error, props.themeColor));
      return;
    }
    let logoUploadResult = null;
    if (logo) {
      setLoadingMessage('Uploading Company Logo');
      setIsLoading(true);
      logoUploadResult = await uploadProfilePicture();

      if (logoUploadResult === null) {
        return;
      }
    }

    const data = companyFields.filter(field => field.name !== 'Logo');

    data.push({
      id: logoId,
      name: 'Logo',
      icon: null,
      type: 'IMAGE',
      value: logoUploadResult ? logoUploadResult.url : null,
      privacyMode: 'PUBLIC_ALLOW_EXPORT',
      privacyModeToggleAllowed: false,
      layout: 'LIST',
    });

    const requestData = {
      portalBrandingType,
      stage,
      status,
      data,
    };

    try {
      setLoadingMessage('Saving company details');
      setIsLoading(true);

      const success = await props.onUpdateCompanyDetails(
        requestData,
        showToast,
      );
    } catch (error) {
      showToast('Failed to save profile', props.themeColor);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.CreateProfile}>
      {showCropper && (
        <ImageCropper
          profilePicture={compressedImage}
          onChangeProfilePicture={image => {
            setCompressedImage(null);
            setLogo(image);
            setLogoPreview(image);
          }}
          onClose={() => setShowCropper(false)}
          themeColor={props.themeColor}
        />
      )}
      {isLoading && (
        <Spin
          tip={loadingMessage}
          size={'large'}
          style={{color: props.themeColor}}
          fullscreen
        />
      )}
      <div className={classes.Header}></div>

      <div className={commonClasses.Wrapper}>
        <div
          className={commonClasses.TopContainer}
          style={{justifyContent: 'center'}}
        >
          <div className={commonClasses.Title}>
            <h1>Company Details</h1>
          </div>
        </div>
        <div>
          <div className={classes.CreateProfileFormContainer}>
            {/* Profile picture rendering */}
            {renderProfilePictureForDesktop()}
            {renderProfilePictureForMobile()}
          </div>
          <div className={classes.InputContainer}>
            {/* Left column */}
            <div>
              {companyFields
                .filter(field => leftCompanyFields.includes(field.name))
                  .map((field, index) => renderField(field, index, false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField))}
            </div>
            {/* Right column */}
            <div>
              {companyFields
                .filter(field => !leftCompanyFields.includes(field.name))
                  .map((field, index) => renderField(field, index, false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField))}

              {!isReadOnly && (
                <div
                  className={classes.AddFieldButton}
                  onClick={() => setShowAddFieldModal(true)}
                >
                  <p>+ Add Field</p>
                </div>
              )}
            </div>
              {!isReadOnly && (
                  <div className={classes.ActionButtonsContainer}>
                    <button
                        style={{
                          background: `linear-gradient(91.3deg, ${
                              props.themeColor
                          } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                          marginRight: '10px', // Add margin to create the gap
                        }}
                        className={classes.SaveButton}
                        disabled={saveButtonIsClicked}
                        onClick={() => onSaveHandler()}
                    >
                      Save
                    </button>

                    <button
                        className={classes.ChangeLayoutButton}
                        disabled={saveButtonIsClicked}
                        onClick={() => setShowChangeLayoutModal(true)}
                    >
                      Change Layout
                    </button>
                  </div>
              )}
          </div>
        </div>
      </div>

      {showAddFieldModal && (
        <AddFieldModal
          setShowModal={setShowAddFieldModal}
          themeColor={props.themeColor}
          profileFields={companyFields}
          onAddProfileField={onAddProfileField}
        />
      )}

      {showChangeLayoutModal && (
        <ChangeLayoutModal
          setShowModal={setShowChangeLayoutModal}
          themeColor={props.themeColor}
          profileFields={companyFields.filter(
            field => field.privacyMode !== PROFILE_DATA_PRIVACY_MODE.PRIVATE,
          )}
          onUpdateProfileFields={onUpdateProfileFields}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    themeColor: state.home.status.themeColor,
    //profilePicture: state.profile.profilePicture,
    loading: state.teams.loading,
    companyDetails: state.teams.companyDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onChangeProfilePicture: image =>
    //   dispatch(actions.changeProfilePicture(image)),
    onGetCompanyDetails: () => dispatch(getTeamsCompanyDetails()),
    onUploadFile: file => dispatch(actions.uploadFile(file)),

    onUpdateCompanyDetails: (data, showToast) =>
      dispatch(updateTeamsCompanyDetails(data, showToast)),
    onUploadCompanyLogo: logo => dispatch(uploadCompanyLogo(logo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsNew);
