import React, {useEffect, useState} from 'react';
import classes from './CreateOrUpdateUser.module.scss'
import {FiAnchor, FiCreditCard} from "react-icons/fi";
import {MdAlternateEmail, MdArrowDropDown, MdCheck, MdClose, MdOutlineClose} from "react-icons/md";
import {createGradient} from "shared/constants";
import * as actions from "store/actions";
import {createOrUpdateUser, deleteUser} from "store/actions";
import {connect} from "react-redux";
import validator from "validator";
import {ClipLoader} from "react-spinners";
import {AiFillCloseCircle, AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import {IoMdCheckmarkCircleOutline, IoMdCloseCircle} from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import ct from "countries-and-timezones";
import {isValidPhoneNumber} from "libphonenumber-js";
import {showToast} from "common/utils";
import {Select, Space, Switch, Tooltip} from "antd";
import {USER_ROLE, USER_STATUS} from "../../../../../../../common/enums";
import Backdrop from "../../../../../../UI/Backdrop/Backdrop";
import SetAsAdminConfirmationPopup from "../ViewUser/SetAsAdminConfirmationPopup/SetAsAdminConfirmationPopup";
import DeleteConfirmationPopup from "../ViewUser/DeleteConfirmationPopup/DeleteConfirmationPopup";

const CreateOrUpdateUser = (props) => {
    const [firstName, setFirstNme] = useState(props.mode === "edit" ? props.data.firstName : '');
    const [lastName, setLastName] = useState(props.mode === "edit" ? props.data.lastName : '');
    const [username, setUsername] = useState(props.mode === "edit" ? props.data.username : '');
    const [contactNo, setContactNo] = useState(props.mode === "edit" ? props.data.contactNumber : '');
    const [email, setEmail] = useState(props.mode === "edit" ? props.data.email : '');
    const [selectedProducts, setSelectedProducts] = useState(props.mode === "edit" ? props.data.products.map(product => product.link) : []);
    const [role, setRole] = useState(props.mode === "edit" ? props.data.role : USER_ROLE.CORPORATE_USER);
    const [status, setStatus] = useState(props.mode === "edit" ? props.data.status : USER_STATUS.ACTIVE);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isUsernameUnavailable, setIsUsernameUnavailable] = useState(false);
    const [userDeletePopupVisible, setUserDeletePopupVisible] =
        useState(false);
    const [userToAdminPopupVisible, setUserToAdminPopupVisible] = useState(false);
    const [allProducts, setAllProducts] = useState([]);

    const onResetForm = () => {
        setUsername('');
        setFirstNme('');
        setLastName('');
        setContactNo('');
        setEmail('');
        setRole(USER_ROLE.CORPORATE_USER);
        setStatus(USER_STATUS.ACTIVE);
        setSelectedProducts([]);
    };

    const onSubmit = async () => {
        if (formIsValid()) {
            console.log(selectedProducts);
            const data = {
                id: props.mode === "edit" ? props.data.id : undefined,
                firstName,
                lastName,
                username,
                email,
                contactNumber: contactNo.charAt(0) === '+' ? contactNo : "+" + contactNo,
                role,
                status,
                products: selectedProducts.map((link) => ({
                    link: link
                }))
            };
            try {
                setIsSubmitLoading(true)
                await props.onCreateOrUpdateUser(data, props.mode)
                showToast(`User ${props.mode === "edit" ? 'updated' : 'created'} successfully!`, props.themeColor);
                onResetForm();
                props.onClose();
                props.onGetCompanyUsers();
                props.onGetCompanyProducts();
            } catch (e) {
                console.log(e);
                const errorCode = e.response?.data?.errorCode;
                if (errorCode === "DUPLICATE_EMAIL") showToast("Email address already exists, please try again", props.themeColor);
                else if (errorCode === "DUPLICATE_USERNAME") showToast("Username already exists, please try again", props.themeColor);
                else if (errorCode === "USER_LIMIT_IS_OVER") showToast("Company user limit reached, please contact Commercial Prime", props.themeColor);
                else if (errorCode === "REG_CONFIRM_EMAIL_FAILED") showToast("Failed to send confirmation e-mail, user not created", props.themeColor);
                else if (errorCode === "PRODUCT_NOT_FOUND") showToast("One or more products not found", props.themeColor);
                else if (errorCode === "PRODUCT_DOES_NOT_BELONG_TO_COMPANY") showToast("One or more products do not belong to your company", props.themeColor);
                else if (errorCode === "PRODUCT_DOES_NOT_BELONG_TO_COMPANY") showToast("One or more products do not belong to your company", props.themeColor);
                else if (errorCode === "PRODUCT_ALREADY_REGISTERED") showToast("One or more products have already been registered", props.themeColor);
                else if (errorCode === "INVALID_PRODUCT_REGISTRATION") showToast("One or more products have invalid registration status", props.themeColor);
                else showToast("Something went wrong, please try again", props.themeColor);
            } finally {
                setIsSubmitLoading(false);
            }
        }
    };

    const onDelete = async () => {
        if (props.data.username === props.loggedInUser) {
            showToast("Cannot delete currently logged in user", props.themeColor);
            return;
        }

        try {
            setIsDeleteLoading(true)
            await props.onDeleteUser(props.data.id)
            showToast(`User deleted successfully!`, props.themeColor);
            onResetForm();
            props.onClose();
            props.onGetCompanyUsers();
            props.onGetCompanyProducts();
        } catch (e) {
            console.log(e);
            const errorCode = e.response?.data?.errorCode;
            showToast("Something went wrong, please try again", props.themeColor);
        } finally {
            setIsDeleteLoading(false);
        }
    };

    const getCountry = () => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const obj = ct.getCountryForTimezone(tz)
        return obj.id.toLowerCase();
    }

    const handlePhoneChange = (phone) => {
        setContactNo(phone);
    };

    const handleProductsChange = (value) => {
        setSelectedProducts(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.mode === "edit" && (username === props.data.username)) {
                    setIsUsernameUnavailable(false);
                    return;
                }

                if (username.length < 4) {
                    setIsUsernameUnavailable(true);
                } else {
                    const response = await props.onIsUserNameExist(username);
                    setIsUsernameUnavailable(response);
                }

            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [username, props.onIsUserNameExist]);

    useEffect(() => {
        if (props.products.length === 0) {
            props.onGetCompanyProducts();
        }
    }, []);


    useEffect(() => {
        setAllProducts(props.products.map((product) => {
            const isRegistered = product.status === "REGISTERED";
            const isDisabled = props.mode === "edit" ? (isRegistered && product.ownerId !== props.data.id) : isRegistered;
            return ({
                label: `${product.name} - ${product.link}${isDisabled ? ' (Registered)' : ''}`,
                value: product.link,
                disabled: isDisabled
            })
        }));
    }, [props.products]);

    const formIsValid = () => {

        if (firstName?.trim() === '') {
            showToast('First name cannot be empty', props.themeColor);
            return false;
        }
        if (!validator.isAlpha(firstName)) {
            showToast('First name should contain only letters', props.themeColor);
            return false;
        }
        if (lastName?.trim() === '') {
            showToast('Last name cannot be empty', props.themeColor);
            return false;
        }
        if (!validator.isAlpha(lastName)) {
            showToast('Last name should contain only letters', props.themeColor);
            return false;
        }

        if (username?.trim() === '') {
            showToast('Username cannot be empty', props.themeColor);
            return false;
        }
        if (isUsernameUnavailable) {
            showToast('Username is invalid or unavailable', props.themeColor);
            return false;
        }
        if (!validator.isAlphanumeric(username)) {
            showToast('Username should contain only letters and numbers', props.themeColor);
            return false;
        }
        if (email?.trim() === '') {
            showToast('Email cannot be empty', props.themeColor);
            return false;
        }
        if (!validator.isEmail(email)) {
            showToast('Invalid email address', props.themeColor);
            return false;
        }
        if (contactNo?.trim() === '') {
            showToast('Contact number cannot be empty', props.themeColor);
            return false;
        }
        if (!isValidPhoneNumber(contactNo.charAt(0) === '+' ? contactNo : "+" + contactNo)) {
            showToast('Invalid contact number', props.themeColor);
            return false;
        }
        return true;
    };

    // const companyUserUpdate = () => {
    //     if (formIsValid()) {
    //         props.onUpdateCompanyUser(
    //             {
    //                 accountId: props.data.id,
    //                 contactNo: phoneNumber.startsWith('+')
    //                     ? phoneNumber
    //                     : '+' + phoneNumber,
    //                 email,
    //                 firstName: splitName[0],
    //                 lastName: splitName[1],
    //                 qrLink: QRCode,
    //                 status: status.toUpperCase(),
    //                 userName: username,
    //                 role: role,
    //             },
    //             showToast,
    //             props.onClose,
    //             props.loggedInUser,
    //             {
    //                 username: props.data.userName,
    //                 role: props.data.role,
    //             },
    //         );
    //     }
    // };


    return (
        <section className={classes.NewUser}>

            {userToAdminPopupVisible ? (
                <Backdrop onClick={() => setUserToAdminPopupVisible(false)}>
                    <SetAsAdminConfirmationPopup
                        onClose={() => {
                            setUserToAdminPopupVisible(false);
                        }}
                        color={props.themeColor}
                        onConfirm={() => {
                            setRole('ROLE_COMPANY_ADMIN');
                            setUserToAdminPopupVisible(false);
                        }}
                        username={firstName !== '' ? firstName : 'this user'}
                    />
                </Backdrop>
            ) : null}

            {userDeletePopupVisible ? (
                <Backdrop onClick={() => setUserDeletePopupVisible(false)}>
                    <DeleteConfirmationPopup
                        onClose={() => {
                            setUserDeletePopupVisible(false);
                        }}
                        color={props.themeColor}
                        onDelete={onDelete}
                        loading={isDeleteLoading}
                    />
                </Backdrop>
            ) : null}

            <div className={classes.CloseButtonContainer}>
                <h1>{props.mode === "edit" ? "Update" : "Create"} User</h1>
                <div onClick={props.onClose}>
                    <MdClose color="#C5C5C5" size={20}/>
                </div>
            </div>

            <div className={classes.InputContainer}>
                <div className={classes.InputsWrapper}>
                    <div className={classes.Detail}>
                        <p className={classes.Label}>First Name</p>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <FiAnchor size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="First name" value={firstName}
                                       onChange={event => setFirstNme(event.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <p className={classes.Label}>Username</p>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <FiAnchor size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="Username" value={username}
                                       onChange={event => setUsername(event.target.value)}/>
                                <div className={classes.TickIcon}>
                                    {username.length === 0 ? null :
                                        (isUsernameUnavailable ?
                                            <Tooltip title={"Username is invalid or unavailable"}><IoMdCloseCircle
                                                color={'#ff3b3b'} size={20}/></Tooltip>
                                            : <Tooltip title={"Username is available"}><IoMdCheckmarkCircleOutline
                                                color={'rgb(113 191 63)'} size={20}/></Tooltip>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <p className={classes.Label}>Contact Number</p>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <PhoneInput
                                    dropdownStyle={{background: '#242424', color: 'white'}}
                                    inputStyle={{
                                        fontFamily: 'inherit',
                                        fontSize: 'inherit',
                                        background: 'transparent',
                                        width: '100%',
                                        border: 'none',
                                    }}
                                    country={getCountry()}
                                    countryCodeEditable={false}
                                    value={contactNo}
                                    onChange={handlePhoneChange}
                                    enableSearch={true}
                                    disableSearchIcon={true}
                                    inputClass={classes.PhoneInput}
                                    buttonClass={classes.ButtonClass}
                                    dropdownClass={classes.DropdownClass}
                                    searchClass={classes.SearchClass}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.InputsWrapper}>
                    <div className={classes.Detail}>
                        <p className={classes.Label}>Last Name</p>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <FiAnchor size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="Last Name" value={lastName}
                                       onChange={event => setLastName(event.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <p className={classes.Label}>Email Address</p>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <MdAlternateEmail size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="Email Address" value={email}
                                       onChange={event => setEmail(event.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <p className={classes.Label}>Assigned Products</p>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input} style={{paddingRight: 0}}>
                                <div className={classes.IconContainer}>
                                    <FiCreditCard size={16} color="#8d8d8d"/>
                                </div>
                                <Select
                                    loading={props.isProductDataLoading}
                                    menuItemSelectedIcon={<MdCheck color={props.themeColor} size={18}/>}
                                    suffixIcon={<MdArrowDropDown color={"#C5C5C5"} size={28}/>}
                                    removeIcon={<MdOutlineClose color={"#C5C5C5"} size={14}/>}
                                    mode="multiple"
                                    placeholder="Select Products"
                                    value={selectedProducts}
                                    onChange={handleProductsChange}
                                    style={{width: '100%', height: '100%', padding: 0, margin: 0}}
                                    options={allProducts}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.MobileInputWrapper}>
                    <div className={classes.Detail}>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <FiAnchor size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="First name" value={firstName}
                                       onChange={event => setFirstNme(event.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <FiAnchor size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="Last Name" value={lastName}
                                       onChange={event => setLastName(event.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <FiAnchor size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="Username" value={username}
                                       onChange={event => setUsername(event.target.value)}/>
                                <div className={classes.TickIcon}>
                                    {username.length === 0 ? null :
                                        (isUsernameUnavailable ?
                                            <AiFillCloseCircle color={'#ff3b3b'}/>
                                            : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={classes.Detail}>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <div className={classes.IconContainer}>
                                    <MdAlternateEmail size={16} color="#8d8d8d"/>
                                </div>
                                <input placeholder="Email Address" value={email}
                                       onChange={event => setEmail(event.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.Detail}>
                        <div style={{background: props.themeColor}} className={classes.InputWrapper}>
                            <div className={classes.Input}>
                                <PhoneInput
                                    country={getCountry()}
                                    countryCodeEditable={false}
                                    value={contactNo}
                                    onChange={handlePhoneChange}
                                    enableSearch={true}
                                    disableSearchIcon={true}
                                    inputClass={classes.PhoneInput}
                                    buttonClass={classes.ButtonClass}
                                    dropdownClass={classes.DropdownClass}
                                    searchClass={classes.SearchClass}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={classes.BottomButtonWrapper}>
                <div className={classes.ButtonGroup}>
                    <button
                        className={classes.Button}
                        onClick={() => props.mode === "edit" ? setUserDeletePopupVisible(true) : onResetForm()}
                    >
                        {props.mode === "edit" ? "Delete" : "Reset"}
                    </button>

                    {props.mode === "edit" && <Space size="middle">
                        <Switch
                            checked={status === USER_STATUS.ACTIVE}
                            checkedChildren={<AiOutlineCheck/>}
                            unCheckedChildren={<AiOutlineClose/>}
                            onChange={() => setStatus(status === USER_STATUS.ACTIVE ? USER_STATUS.INACTIVE : USER_STATUS.ACTIVE)}
                        />
                        <label>
                            User
                            <br/>
                            Active
                        </label>
                    </Space>}

                    <Space size="middle">
                        <Switch
                            checked={role === USER_ROLE.COMPANY_ADMIN}
                            checkedChildren={<AiOutlineCheck/>}
                            unCheckedChildren={<AiOutlineClose/>}
                            onChange={checked =>
                                checked
                                    ? setUserToAdminPopupVisible(true)
                                    : setRole(USER_ROLE.CORPORATE_USER)
                            }
                        />
                        <label>
                            Company
                            <br/>
                            Admin
                        </label>
                    </Space>
                </div>

                <button
                    className={classes.Button}
                    style={{
                        background:
                            `linear-gradient(91.3deg, 
                ${props.themeColor} 
                1.61%, 
                ${createGradient(props.themeColor, 50)} 
                100%)`
                    }}
                    onClick={onSubmit}
                >
                    {isSubmitLoading ? (
                        <div className={classes.SpinnerContainer}>
                            <ClipLoader loading={true} color={"white"} size={18}/>
                        </div>
                    ) : (
                        `${props.mode === "edit" ? "Update" : "Create"} User`
                    )}
                </button>
            </div>
        </section>
    )
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.user,
        products: state.teams.products,
        isProductDataLoading: state.teams.isProductDataLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
        onGetCompanyProducts: () => dispatch(actions.getProducts()),
        onGetCompanyUsers: () => dispatch(actions.getUsers()),
        onCreateOrUpdateUser: (data, mode) => dispatch(createOrUpdateUser(data, mode)),
        onDeleteUser: (userId) => dispatch(deleteUser(userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrUpdateUser);
