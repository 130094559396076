import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';

export const toggleExportImportDropdown = () => {
    return {
        type: actionTypes.TOGGLE_EXPORT_IMPORT_DROPDOWN,
    };
};

const addNewContactStart = () => {
    return {
        type: actionTypes.ADD_NEW_CONTACT_START,
    };
};

const addNewContactEnd = () => {
    return {
        type: actionTypes.ADD_NEW_CONTACT_END,
    };
};

export const changeSelectedId = (payload) => {
    return {
        type: actionTypes.CHANGE_SELECTED_ID,
        payload
    }
};

const getContactsStart = () => {
    return {
        type: actionTypes.GET_CONTACTS_START,
    };
};

const getContactsSuccess = (contacts) => {
    return {
        type: actionTypes.GET_CONTACTS_SUCCESS, contacts,
    };
};

const getContactsFail = () => {
    return {
        type: actionTypes.GET_CONTACTS_FAIL,
    };
};

export const getAllContacts = () => {
    return dispatch => {
        dispatch(getContactsStart());
        axios.get('/api/user-service/get-contacts',).then(res => {
            dispatch(getContactsSuccess(res.data));
        }).catch(() => dispatch(getContactsFail()));
    };
};

export const getContactsByProfileId = (profileId) => {
    return dispatch => {
        dispatch(getContactsStart());
        axios.get('/api/user-service/get-contacts-by-profile-name?profileId=' + profileId).then(res => {
            dispatch(getContactsSuccess(res.data));
        }).catch(() => dispatch(getContactsFail()));
    };
};

export const getContact = (contactId) => {
    return dispatch => {
        return axios.get(`/api/user-service/get-contact?contactId=${contactId}`);
    }
}

export const addContact = (contactData) => {
    return dispatch => {
        return axios.post('/api/user-service/add-contact', contactData).then(res => {
            dispatch(getAllContacts());
        });
    };
};

export const deleteContact = (contactId) => {
    return dispatch => {
        return axios.delete(`/api/user-service/delete-contact?contactId=${contactId}`).then(res => {
            dispatch(getAllContacts());
        });
    }
}

export const updateContact = (contactDetails) => {
    return dispatch => {
        return axios.put('api/user-service/update-contact', contactDetails).then(res => {
            dispatch(getAllContacts());
        })
    };
};
