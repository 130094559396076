import axios from "../../../common/axios";

export const getAnalyticCountList = () => {
  return async dispatch => {
    try {
      const response = await axios.get("/api/product-service/get-analytic-counts");
      return response.data;
    } catch (e) {
      console.log(e)
    }
  };
};

export const getAnalyticsViews = () => {
  return async dispatch => {
    try {
      const response = await axios.get("/api/product-service/get-analytic-views-over-time");
      return response.data;
    } catch (e) {
      console.log(e)
    }
  };
};

export const getAnalyticsEngagements = () => {
  return async dispatch => {
    try {
      const response = await axios.get("/api/product-service/get-analytic-engagement-over-time");
      return response.data;
    } catch (e) {
      console.log(e)
    }
  }
}

export const postAnalyticsInfo = (data) => {
  return async dispatch => {
    try {
      const response = await axios.post("/api/product-service/post-analytics-info", data);
      return response.data;
    } catch (e) {
      console.log(e)
    }
  }
}
