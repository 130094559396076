import * as actionTypes from '../actions/teams/actionTypes';

const initialState = {
  products: [],
  users: [],
  isProductDataLoading: false,
  isUserDataLoading: false,
  expanded: false,
  loading: false,
  dLoading: false,
  profileData: [],
  brandingLogo: '',
  companyDetails: null,
  brandingDetails: null,
};

const getUsersStart = (state) => {
  return {...state, isUserDataLoading: true};
};

const getUsersSuccess = (state, action) => {
  return {...state, users: action.payload, isUserDataLoading: false};
};

const getUsersFail = (state) => {
  return {...state, users: [], isUserDataLoading: false};
};

const getProductsStart = (state) => {
  return {...state, isProductDataLoading: true};
};

const getProductsSuccess = (state, action) => {
  return {...state, products: action.payload, isProductDataLoading: false};
};

const getProductsFail = (state) => {
  return {...state, isProductDataLoading: false};
};

const setExpand = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const changeBrandingLogo = (state, action) => {
  return {
    ...state,
    brandingLogo: action.payload,
  };
};

const setAllUsers = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const updateUsersStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const updateUsersSuccess = (state, action) => {
  const updatedUser = action.payload;
  const updatedUsers = state.users.map(user =>
    user.id === updatedUser.id ? updatedUser : user,
  );
  return {...state, users: updatedUsers};
};

const updateUsersEnd = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const getAllProfilesStart = (state, action) => {
  return {...state, loading: true};
};

const getAllProfilesSuccess = (state, action) => {
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
};

const getAllProfilesFail = (state, action) => {
  return {...state, loading: false};
};

const updateCompanyDetailsStart = (state, action) => {
  return {...state, loading: true};
};

const updateCompanyDetailsEnd = (state, action) => {
  return {...state, loading: false};
};

const updateCompanyDetailsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    companyDetails: action.payload,
    error: null,
  };
};

const updateTeamsBrandingDetailsStart = (state, action) => {
  return {...state, loading: true};
};

const updateTeamsBrandingDetailsSuccess = (state, action) => {
  return {...state, loading: false, companyDetails: action.payload};
};

const updateTeamsBrandingDetailsEnd = (state, action) => {
  return {...state, loading: false};
};

const addNewTeamUserStart = (state, action) => {
  return {...state, loading: true};
};

const addNewTeamUserSuccess = (state, action) => {
  const newUser = action.payload;
  const qrLink =
    newUser.products && newUser.products.length > 0
      ? newUser.products[0].link
      : '';

  const userWithQrLink = {
    ...newUser,
    qrLink: qrLink,
  };

  return {
    ...state,
    loading: false,
    users: [...state.users, action.payload],
  };
};

const addNewTeamUserEnd = (state, action) => {
  return {...state, loading: false};
};

const deleteTeamUserStart = (state, action) => {
  return {...state, dLoading: true};
};

const deleteTeamUserEnd = (state, action) => {
  return {...state, dLoading: false};
};

const getCompanyDetailsStart = state => {
  return {...state, loading: true, error: null};
};

const getCompanyDetailsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    companyDetails: action.payload,
    error: null,
  };
};

const getCompanyDetailsFail = (state, action) => {
  return {...state, loading: false, error: action.payload};
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_USERS_START:
      return getUsersStart(state, action);
    case actionTypes.GET_COMPANY_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_COMPANY_USERS_FAIL:
      return getUsersFail(state, action);

    case actionTypes.GET_COMPANY_PRODUCTS_START:
      return getProductsStart(state, action);
    case actionTypes.GET_COMPANY_PRODUCTS_SUCCESS:
      return getProductsSuccess(state, action);
    case actionTypes.GET_COMPANY_PRODUCTS_FAIL:
      return getProductsFail(state, action);

    case actionTypes.CHANGE_EXPAND:
      return setExpand(state, {payload: {expanded: !state.expanded}});
    case actionTypes.CHANGE_BRANDING_IMAGE:
      return changeBrandingLogo(state, action);
    case actionTypes.UPDATE_COMPANY_USER_START:
      return updateUsersStart(state, action);
    case actionTypes.UPDATE_COMPANY_USER_SUCCESS:
      return updateUsersSuccess(state, action);
    case actionTypes.UPDATE_COMPANY_USER_END:
      return updateUsersEnd(state, action);
    case actionTypes.GET_ALL_TEAM_USER_PROFILES_START:
      return getAllProfilesStart(state, action);
    case actionTypes.GET_ALL_TEAM_USER_PROFILES_SUCCESS:
      return getAllProfilesSuccess(state, action);
    case actionTypes.GET_ALL_TEAM_USER_PROFILES_FAIL:
      return getAllProfilesFail(state, action);
    case actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_START:
      return updateCompanyDetailsStart(state, action);
    case actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_SUCCESS:
      return updateCompanyDetailsSuccess(state, action);
    case actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_END:
      return updateCompanyDetailsEnd(state, action);
    case actionTypes.ADD_NEW_TEAM_USER_START:
      return addNewTeamUserStart(state, action);
    case actionTypes.ADD_NEW_TEAM_USER_SUCCESS:
      return addNewTeamUserSuccess(state, action);
    case actionTypes.ADD_NEW_TEAM_USER_END:
      return addNewTeamUserEnd(state, action);
    case actionTypes.DELETE_TEAM_USER_START:
      return deleteTeamUserStart(state, action);
    case actionTypes.DELETE_TEAM_USER_END:
      return deleteTeamUserEnd(state, action);
    case actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_START:
      return updateTeamsBrandingDetailsStart(state, action);
    case actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_SUCCESS:
      return updateTeamsBrandingDetailsSuccess(state, action);
    case actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_END:
      return updateTeamsBrandingDetailsEnd(state, action);
    case actionTypes.GET_TEAMS_COMPANY_DETAILS_START:
      return getCompanyDetailsStart(state, action);
    case actionTypes.GET_TEAMS_COMPANY_DETAILS_SUCCESS:
      return getCompanyDetailsSuccess(state, action);
    case actionTypes.GET_TEAMS_COMPANY_DETAILS_FAIL:
      return getCompanyDetailsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
