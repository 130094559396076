import VCard from 'vcard-creator';
import vCard from 'vcf';
import {showToast} from "../common/utils";

export const importFromDevice = (
  userId,
  onAddContact,
  onGetAllContacts,
  themeColor
) => {
  const callback = event => {
    let supported = true;
    for (let i = 0; i < event.target.files.length; i++) {
      if (supported && event.target.files[i].type.split('/')[1] !== 'vcard') {
        supported = false;
      }
    }

    if (!supported) {
      return showToast('Select .vcf files only', themeColor);
    }

    for (let i = 0; i < event.target.files.length; i++) {
      var reader = new FileReader();

      reader.readAsText(event.target.files[i], 'UTF-8');

      reader.onload = readerEvent => {
        var content = readerEvent.target.result;
        const card = new vCard().parse(content);

        const contact = {
          userId,
          name: card.data.fn._data,
          contactNo: card.data.tel._data,
        };

        onAddContact(contact, () => {
          if (i === event.target.files.length - 1) {
            onGetAllContacts();
            showToast('Contacts imported from device!', themeColor);
          }
        });

        document
          .getElementById('inpImportFromDevice')
          .removeEventListener('input', callback);
      };
    }
  };

  document.getElementById('inpImportFromDevice').oninput = callback;
  document.getElementById('inpImportFromDevice').click();
};

export const importFromGoogle = (
  userId,
  onAddContact,
  onGetAllContacts,
  themeColor
) => {
  window.tokenClient.callback = async resp => {
    if (resp.error !== undefined) {
      throw resp;
    }
    const req = window.gapi.client.request({
      method: 'GET',
      path: 'https://content-people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers',
    });
    req.execute(res => {
      res.connections.forEach((connection, index) => {
        const contact = {
          userId,
          name: connection.names[0].displayName,
          contactNo: connection.phoneNumbers[0].canonicalForm,
        };

        onAddContact(contact, () => {
          if (index === res.connections.length - 1) {
            onGetAllContacts();
            showToast('Contacts imported from Google!', themeColor);
          }
        });
      });
    });
  };
  if (window.gapi.client.getToken() === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    window.tokenClient.requestAccessToken({prompt: 'consent'});
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    window.tokenClient.requestAccessToken({prompt: ''});
  }
};

export const exportToDevice = (contacts, themeColor) => {
  contacts.forEach(contact => {
    const myVCard = new VCard();

    const splitName = contact.name.split(' ');
    const firstName = splitName[0];
    const lastName = splitName[1];

    myVCard
      .addName(lastName, firstName)
      .addPhoneNumber(contact.contactNo, 'CELL');

    const data = myVCard.buildVCard();

    var url = 'data:text/x-vcard;charset=utf-8,' + encodeURIComponent(data);

    var downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${firstName}_${lastName}.vcf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
  showToast('Exported to device!', themeColor);
};

export const exportToGoogle = (contacts, themeColor) => {
  window.tokenClient.callback = async resp => {
    if (resp.error !== undefined) {
      throw resp;
    }
    const googleContacts = [];
    contacts.forEach(contact => {
      const contactObj = {
        contactPerson: {
          names: [
            {
              givenName: contact.name,
            },
          ],
          phoneNumbers: [
            {
              value: contact.contactNo,
            },
          ],
        },
      };

      googleContacts.push(contactObj);
    });

    const req = window.gapi.client.request({
      method: 'POST',
      path: 'https://content-people.googleapis.com/v1/people:batchCreateContacts',
      body: {
        contacts: googleContacts,
      },
    });

    req.execute(() => {
      showToast('Exported to Google!', themeColor);
    });
  };
  if (window.gapi.client.getToken() === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    window.tokenClient.requestAccessToken({prompt: 'consent'});
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    window.tokenClient.requestAccessToken({prompt: ''});
  }
};
