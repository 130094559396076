import commonClasses from '../Wrapper/Wrapper.module.scss';
import classes from './Profiles.module.scss';

import button from '../../../../../UI/Button/Button';
import {BiSearchAlt} from 'react-icons/bi';
import React, {useEffect, useState} from 'react';
import ProfileCard from '../../../Home/ProfileCard/ProfileCard';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as actions from '../../../../../../store/actions';
import {PROFILE_DATA_FIELDS} from "../../../../../../common/enums";

const Profiles = props => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(undefined);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (props.profileData?.length === 0) {
      props.onGetAllTeamsProfiles();
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      setFilteredData(
          props.profileData.map(user => ({
            ...user,
            profiles: user.profiles.filter(profile => {
              // Create a lookup map for the profile data
              const dataMap = profile.data.reduce((acc, item) => {
                acc[item.name] = item.value.toLowerCase();
                return acc;
              }, {});

              // Combine all searchable fields into a single string
              const searchableText = [
                dataMap[PROFILE_DATA_FIELDS.PROFILE_NAME.name] || '',
                dataMap[PROFILE_DATA_FIELDS.FIRST_NAME.name] || '',
                dataMap[PROFILE_DATA_FIELDS.LAST_NAME.name] || '',
                dataMap[PROFILE_DATA_FIELDS.COMPANY.name] || ''
              ].join(' ');

              // Check if the term exists in the combined searchable text
              return searchableText.includes(term);
            })
          })).filter(user => user.profiles.length > 0)
      );
    } else {
      setFilteredData(props.profileData);
    }
  }, [searchTerm, props.profileData]);

  return (
      <div className={commonClasses.Wrapper}>
        <div className={commonClasses.TopContainer}>
          <div className={commonClasses.Title}>
            <h1>Profiles</h1>
            {props.profileData?.length > 0 && <span>{props.profileData?.reduce((acc, user) => {
              return acc + user.profiles.length;
            }, 0)}</span>}
          </div>
          <div className={commonClasses.SearchContainer}>
            <div>
              <BiSearchAlt/>
              <input
                  type="text"
                  placeholder="Search Profiles..."
                  value={searchTerm}
                  onChange={event => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={classes.Content}>
          {props.loading ? (
              <div className={commonClasses.SpinnerContainer}>
                <ClipLoader color={props.themeColor} loading={true} size={60}/>
              </div>
          ) : filteredData.length === 0 ? (
              <h1 className={commonClasses.NoData}>No data available</h1>
          ) : (
              filteredData.map(user => {
                return (
                    <div key={user.id} style={{width: '100%', marginBottom: 24}}>
                      <h3 style={{color: 'white'}}>{user.name}</h3>
                      <hr style={{borderWidth: 0.5, borderColor: 'gray'}}/>
                      <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginTop: 40,
                            gap: 8,
                          }}
                      >
                        {user.profiles.map(profile => {
                          const isProfileActive = profile.id === user.activeProfileId;
                          return (
                              <ProfileCard
                                  belongsToCompany={true}
                                  isActive={isProfileActive}
                                  profile={profile}
                                  key={profile.id}
                              />
                          );
                        })}
                      </div>
                    </div>
                );
              })
          )}

          <button
              className={classes.CreateNewProfileButtonMobile}
              onClick={() => navigate('/create-profile')}
          >
            Create New Profile
          </button>
        </div>
      </div>
  );
};

const mapStateToProps = state => {
  return {
    profileData: state.teams.profileData,
    themeColor: state.home.status.themeColor,
    loading: state.teams.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTeamsProfiles: () => dispatch(actions.getAllTeamsUserProfiles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);
