import * as actionTypes from '../actions/home/actionTypes';

const initialState = {
    isStatusDataLoading: true,
    isProfileDataLoading: true,
    isProfileStatusLoading: false,
    isHpmStatusLoading: false,
    status: {
        themeColor: "#DBB659"
    },
    profiles: [],
    logo: '',
    collaboration: false,
    companyName: 'Commercial Prime'
};

const updateObject = (oldObject, newValues) => ({
    ...oldObject,
    ...newValues
});

const handleStartLoading = (state, loadingType) => updateObject(state, {[loadingType]: true});
const handleSuccess = (state, payload, loadingType, additionalUpdates = {}) => updateObject(state, {
    [loadingType]: false,
    ...additionalUpdates,
    ...payload
});
const handleFail = (state, loadingType) => updateObject(state, {[loadingType]: false});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STATUS_START:
            return handleStartLoading(state, 'isStatusDataLoading');

        case actionTypes.GET_STATUS_SUCCESS:
            return handleSuccess(state, action.payload, 'isStatusDataLoading', {status: {...state.status, ...action.payload}});

        case actionTypes.GET_STATUS_FAIL:
            return handleFail(state, 'isStatusDataLoading');

        case actionTypes.GET_ALL_PROFILES_START:
            return handleStartLoading(state, 'isProfileDataLoading');

        case actionTypes.GET_ALL_PROFILES_SUCCESS:
            return handleSuccess(state, {profiles: action.payload}, 'isProfileDataLoading');

        case actionTypes.GET_ALL_PROFILES_FAIL:
            return handleFail(state, 'isProfileDataLoading');

        case actionTypes.UPDATE_PROFILE_STATUS_START:
            return handleStartLoading(state, 'isProfileStatusLoading');

        case actionTypes.UPDATE_PROFILE_STATUS_SUCCESS:
            return handleSuccess(state, {status: {...state.status, ...action.payload}}, 'isProfileStatusLoading');

        case actionTypes.UPDATE_PROFILE_STATUS_FAIL:
            return handleFail(state, 'isProfileStatusLoading');

        case actionTypes.UPDATE_HIGH_PRIVACY_MODE_START:
            return handleStartLoading(state, 'isHpmStatusLoading');

        case actionTypes.UPDATE_HIGH_PRIVACY_MODE_SUCCESS:
            return handleSuccess(state, {status: {...state.status, ...action.payload}}, 'isHpmStatusLoading');

        case actionTypes.UPDATE_HIGH_PRIVACY_MODE_FAIL:
            return handleFail(state, 'isHpmStatusLoading');

        case actionTypes.CLEAR_PROFILE_DATA:
            return updateObject(state, {profiles: []});

        default:
            return state;
    }
};

export default reducer;
