import * as actionTypes from './actionTypes';
import axios from "../../../common/axios";

const getProductsSuccess = products =>{
    return{
        type:actionTypes.GET_PRODUCTS_BY_USERNAME_SUCCESS,
        products,
    }
}

export const getLinkedProductsByUsername = username =>{
    return dispatch =>{
        axios
            .get(`/api/product-service/get-linked-products?username=${username}`)
            .then(response =>{
                console.log(response.data)
                dispatch(getProductsSuccess(response.data));
            })
            .catch((e=>console.log(e)));
    }
}
