import React from 'react';
import classes from './PreviewConfirmation.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {createGradient} from "../../../../../shared/constants";

const PreviewConfirmation = props => {
  return (
    <div className={classes.PreviewConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <p className={classes.ConfirmationText}>Do you wish to continue?</p>
      <p className={classes.InfoText}>
        The profile you're trying to view will be set as the active profile
      </p>
      <button
        style={{background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 50)} 100%)`}}
        className={classes.ContinueButton}
        onClick={props.onConfirmation}
      >
        Continue
      </button>
    </div>
  );
};

export default PreviewConfirmation;
