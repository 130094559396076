import React from 'react';
import classes from './EnableProfileSelfUpdateConfirmation.module.scss'
import {MdClose} from "react-icons/md";
import {createGradient} from "shared/constants";
import {ClipLoader} from "react-spinners";
import {connect} from "react-redux";
// import { setEnableProfileSelfUpdate } from "store/actions/settings/settings"


const ProfileSelfUpdateSettingConfirmation = (props) => {

  return (
    <section className={classes.ProfileSelfUpdateSettingConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <h1>Are you sure you want to do this?</h1>
        <div onClick={props.onClose}>
          <MdClose color="#C5C5C5" size={20}/>
        </div>
      </div>
      <div>
        {props.enableProfileSelfUpdate ? <p style={{color: "#eaeaea", fontSize: "14px"}}>
          By enabling this setting, users in your organization will be able to update their profiles.
          <br></br>
          You can also set this for users individually in the users section.
        </p> : <p style={{color: "#eaeaea", fontSize: "14px"}}>
          By disabling this setting, users in your organization won't be able to update their profiles.
          <br></br>
          You can also set this for users individually in the users section.
        </p>
        }
      </div>
      <div className={classes.BottomButtonWrapper}>
        <button onClick={props.onClose}>Cancel</button>
        <button
          style={{
            background:
              `linear-gradient(91.3deg, 
                ${props.color} 
                1.61%, 
                ${createGradient(props.color, 50)} 
                100%)`
          }}
          onClick={props.onConfirm}
        >
          {props.loading === true ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader loading={true} color={"white"} size={18}/>
            </div>
          ) : (
            <p>Confirm</p>
          )}
        </button>
      </div>
    </section>
  )
};


const mapStateToProps = state => {
  return {
    loading: state.settings.loading,
      color: state.home.status.themeColor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // confirmEnableProfileSelfUpdate: (change_to) => dispatch(setEnableProfileSelfUpdate(change_to)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProfileSelfUpdateSettingConfirmation);
