import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';
import {signOut} from '../auth/auth';
import {getStatus} from "../home/home";

const getAccountDetailsSuccess = data => {
    return {
        type: actionTypes.GET_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
    };
};

const getAccountDetailsFail = error => {
    return {
        type: actionTypes.GET_ACCOUNT_DETAILS_FAIL,
        payload: error,
    };
};

const getAccountDetailsStart = () => {
    return {
        type: actionTypes.GET_ACCOUNT_DETAILS_START,
    };
};

export const getAccountDetails = () => {
    return async dispatch => {
        dispatch(getAccountDetailsStart());
        try {
            const response = await axios.get('api/user-service/get-account-detail');
            dispatch(getAccountDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            console.log('error:', error.message);
            dispatch(getAccountDetailsFail(error));
            throw error;
        }
    };
};

export const updateAccountDetails = (
    accountDetails,
    logoutStatus,
) => {
    return dispatch => {
        return axios
            .post('api/user-service/update-account-detail', accountDetails)
            .then(res => {
                dispatch(getStatus());

                if (logoutStatus) {
                    setTimeout(() => {
                        dispatch(signOut());
                    }, 1500);
                }
            })
            .catch(err => {
                console.error(err);
                throw err;
            });
    };
};

export const changePassword = (data) => {
    return dispatch => {
        return axios
            .post('/api/user-service/change-password', data)
            .catch(err => {
                console.error(err);
                throw err;
            });
    };
};
