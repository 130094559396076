import SideBar from "./Body/SideBar/SideBar";
import classes from "./Teams.module.scss"
import React from "react";
import {Outlet, useOutletContext} from "react-router-dom";
import Wrapper from "./Body/Wrapper/Wrapper";
import SideDrawer from "./Body/SideDrawer/SideDrawer";
import {connect} from "react-redux";
import Header from "./Header/Header";

const Teams = (props) => {
  const outletContext = useOutletContext();
  const [open, setOpen] = React.useState(true);

  return (
    <div className={classes.TeamsContainer}>
      <Header toggleMenu={outletContext.toggleMenu}/>
        <SideBar open={open} setOpen={setOpen} color={props.themeColor}/>
        <SideDrawer color={props.themeColor}/>
      <Wrapper sideBarOpen={open}>
        <Outlet/>
      </Wrapper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      themeColor: state.home.status.themeColor,
  };
};

export default connect(mapStateToProps)(Teams);
