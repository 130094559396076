import classes from './Sidebar.module.scss';
import {FiUsers} from 'react-icons/fi';
import {BsFillCreditCardFill} from 'react-icons/bs';
import {BiBuildings} from 'react-icons/bi';
import {CgMenuLeft, CgMenuRight} from 'react-icons/cg';
import {MdOutlineBrandingWatermark, MdOutlineSettings} from 'react-icons/md';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES_PATHS} from "../../../../../../common/enums";

const SideBar = ({ open, setOpen, color }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onClickExpand = () => setOpen(!open);

    const menuItems = [
        { path: ROUTES_PATHS.COMPANY_ADMIN.USERS, label: 'Users', icon: <FiUsers /> },
        { path: ROUTES_PATHS.COMPANY_ADMIN.PROFILES, label: 'Profiles', icon: <BsFillCreditCardFill /> },
        { path: ROUTES_PATHS.COMPANY_ADMIN.COMPANY_DETAILS, label: 'Company Details', icon: <BiBuildings /> },
        { path: ROUTES_PATHS.COMPANY_ADMIN.BRANDING, label: 'Branding', icon: <MdOutlineBrandingWatermark /> },
        { path: ROUTES_PATHS.COMPANY_ADMIN.SETTINGS, label: 'Settings', icon: <MdOutlineSettings /> },
    ];

    const renderMenuItems = menuItems.map(({ path, label, icon }) => {
        const isActive = location.pathname.includes(`${ROUTES_PATHS.COMPANY_ADMIN.TEAMS}/${path}`);
        return (
            <div
                key={path}
                className={isActive ? classes.ItemSelected : classes.MenuItems}
                style={{ background: isActive ? color : '' }}
                onClick={() => navigate(path)}
            >
                {icon}
                <span>{label}</span>
            </div>
        );
    });

    return (
        <section className={`${classes.SidebarWrapper} ${!open ? classes.SidebarWrapperCollapsed : ''}`}>
            <div className={classes.Head} style={{justifyContent: open ? 'space-between' : 'center'}}>
                {open && <h1>TEAMS</h1>}
                {open ? (
                    <CgMenuRight onClick={onClickExpand} className={classes.ToggleButton} />
                ) : (
                    <CgMenuLeft onClick={onClickExpand} className={classes.ToggleButton} />
                )}
            </div>
            {renderMenuItems}
        </section>
    );
};

export default SideBar;
