/*import Header from "./Header/Header";
import {useOutletContext} from "react-router-dom";
import classes from './Analytics.module.scss';
import React from "react";
import {connect} from "react-redux";
import BackgroundTop from "../../../../assets/images/createProfile/backgroundTop.png";
import BackgroundBottom from "../../../../assets/images/createProfile/backgroundBottom.png";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from "moment";
import * as actions from '../../../../store/actions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const Analytics = props => {
  const [counts,seCounts] = React.useState([]);
  const [viewsToChart,setViewsToChart] = React.useState([]);
  const [engagementsToChart,setEngagementsToChart] = React.useState([]);
  const outletContext = useOutletContext();

  const hexToRgb = (hex) => {
    hex = hex.replace('#', '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Card taps & Shareable Link  Over Time' },
      zoom: {
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: 'x',
          onZoom: ({ chart }) => {
            const chartType = chart.config.type;
            const axis = chart.scales['x'];
            const { min, max } = axis.options.ticks;

            let dateFormat = 'MMM D, YYYY';
            if (chartType === 'bar') {
              dateFormat = 'MMMM YYYY';
            } else if (chartType === 'line') {
              if (max - min < 7 * 86400000) dateFormat = 'MMM D, YYYY';
            }

            axis.options.time.unit = dateFormat;
            axis.options.ticks.callback = (value) => moment(value).format(dateFormat);
          },
        },
        resetButton: { text: 'Reset Zoom', enabled: true },
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          callback: (value) => (Number.isInteger(value) && value >= 0 ? value : ''),
        },
      },
    },
  };

  const data = {
    datasets: [
      {
        label: 'Card taps',
        data: viewsToChart.map((entry) => entry?.count).reverse(),
        borderColor: props.color,
        backgroundColor: hexToRgb(props.color),
      },
      {
        label: 'Shareable Link ',
        data: engagementsToChart.map((entry) => entry?.count).reverse(),
        borderColor: 'rgb(255,255,255)',
        backgroundColor: 'rgba(255,255,255,0.5)',
      },
    ],
  };

  const allDates = [...new Set([...viewsToChart.map((entry) => entry.date), ...engagementsToChart.map((entry) => entry.date)])];
  allDates.sort((a, b) => new Date(a) - new Date(b));
  const last10Dates = allDates.slice(-10);
  data.labels = last10Dates.map((date) => moment(date).format('MMM D'));
  const filteredViewsData = viewsToChart.filter((entry) => last10Dates.includes(entry.date));
  const filteredEngagementsData = engagementsToChart.filter((entry) => last10Dates.includes(entry.date));
  data.datasets[0].data = filteredViewsData.map((entry) => entry?.count).reverse();
  data.datasets[1].data = filteredEngagementsData.map((entry) => entry?.count).reverse();

  React.useEffect(() => {
    const fetchData = async () => {
      const counts = await props.onGetAnalyticCountList();
      const viewsToChart = await props.onGetAnalyticsViews();
      const engagementToChart = await props.onGetAnalyticsEngagements();
      seCounts(counts);
      setViewsToChart(viewsToChart);
      setEngagementsToChart(engagementToChart);
    }

    fetchData();

  }, []);

  return (
    <div className={classes.Analytics}>
      <Header toggleMenu={outletContext.toggleMenu}/>
      <div style={{backgroundColor: props.color}} className={classes.LeftContainer}>
        <img src={BackgroundTop} alt="commercial prime"/>
        <img src={BackgroundBottom} alt="commercial prime"/>
        <p>
          You can see your
          <br/>
          growth of sharing
        </p>
      </div>

      <div className={classes.RightContainer}>
        <div className={classes.CardContainer}>
          <div className={classes.Card}>
            <span>Card Taps</span>
            <h2>{counts.linkViewsCount === undefined ? 0 : counts.linkViewsCount}</h2>
          </div>
          <div className={classes.Card}>
            <span>Shareable Link </span>
            <h2>{counts.engagementCount === undefined ? 0 : counts.engagementCount}</h2>
          </div>
          <div className={classes.Card}>
            <span>Contact Savings</span>
            <h2>{counts.contactSavingCount === undefined ? 0 : counts.contactSavingCount}</h2>
          </div>
          <div className={classes.Card}>
            <span>Received Contacts </span>
            <h2>{counts.receivedContactCount === undefined ? 0 : counts.receivedContactCount}</h2>
          </div>
        </div>
        <div className={classes.ChartContainer}>
          <Line options={options} data={data}/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    color: state.home.themeColor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAnalyticCountList: () => dispatch(actions.getAnalyticCountList()),
    onGetAnalyticsViews: () => dispatch(actions.getAnalyticsViews()),
    onGetAnalyticsEngagements: () => dispatch(actions.getAnalyticsEngagements()),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Analytics);*/


import Header from "./Header/Header";
import {useOutletContext} from "react-router-dom";
import classes from './Analytics.module.scss';
import React from "react";
import {connect} from "react-redux";
import SVG from "react-inlinesvg";
import analytics from "../../../../assets/images/analytics/Rising-bro.svg"
import BackgroundTop from "../../../../assets/images/createProfile/backgroundTop.png";
import BackgroundBottom from "../../../../assets/images/createProfile/backgroundBottom.png";

const Analytics = props => {
    const outletContext = useOutletContext();

    return (
        <div className={classes.Analytics}>
            <Header toggleMenu={outletContext.toggleMenu}/>
            <div style={{backgroundColor: props.color}} className={classes.LeftContainer}>
                <img src={BackgroundTop} alt="commercial prime"/>
                <img src={BackgroundBottom} alt="commercial prime"/>
                <p>
                    You can see your
                    <br/>
                    growth of sharing
                </p>
            </div>

            <div className={classes.RightContainer}>
                <p>
                    Hold tight!
                    <br/>
                    Analytics are coming soon...
                </p>

                <SVG src={analytics} fill={props.color} className={classes.GraphImage}/>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        color: state.home.status.themeColor,
    };
};

export default connect(mapStateToProps)(Analytics);



