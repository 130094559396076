import classes from "./PrivacyModeChangeConfirm.module.scss";
import {MdOutlineClose} from "react-icons/md";
import {createGradient} from "../../../shared/constants";
import React from "react";
import HighPrivacyMode from "../../../assets/images/highPrivacy.png"

const PrivacyModeChangeConfirm = (props) => {

  return (
    <div className={classes.HighPrivacyChangerPopup}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <p className={classes.ConfirmationText}>
          Do you want to {props.active ? "deactivate" : "activate"}<br/>
          <span style={{color: props.color}}>High Privacy Mode </span>?
      </p>
      <div className={classes.InnerContainer}>
        <img src={HighPrivacyMode} alt={"High Privacy Mode"}/>
        <p className={classes.InfoText}>
            When using High Privacy Mode, your profile is inaccessible to the public using shared links.<br/><br/>
            Your profile will only be accessible through card taps and QR scans.<br/><br/>
            Your data is safeguarded for increased privacy.
        </p>
      </div>
      <button
          style={{background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 50)} 100%)`}}
        className={classes.ConfirmButton}
        onClick={props.onClick}
      >
        Yes, {props.active ? "Deactivate" : "Activate"}
      </button>
    </div>
  )
};

export default PrivacyModeChangeConfirm;
