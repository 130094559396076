import React, {useState} from 'react';
import classes from './AddNewContactPopup.module.scss';
import {MdClose} from 'react-icons/md';
import {BsBriefcase, BsEnvelope, BsPerson, BsTelephone} from 'react-icons/bs';
import {FiAnchor} from 'react-icons/fi';
import {BiNote} from 'react-icons/bi';
import ClipLoader from 'react-spinners/ClipLoader';
import * as actions from '../../../../../store/actions';
import {connect} from 'react-redux';
import {createGradient} from "../../../../../shared/constants";
import PhoneInput from "react-phone-input-2";
import ct from "countries-and-timezones";
import {isValidPhoneNumber} from "libphonenumber-js";
import {showToast} from "../../../../../common/utils";

const AddNewContactPopup = props => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getCountry = () => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const obj = ct.getCountryForTimezone(tz)
        return obj.id.toLowerCase();
    }

    let todayDate = new Date().toISOString().slice(0, 10);

    const postData = {
        "analyticType": "RECEIVED_CONTACTS",
        "country": getCountry().toUpperCase(),
        "date": todayDate,
        "link": '@' + props.products[0].link
    }

    const onSaveHandler = async () => {
        if (formIsValid()) {
            try {
                setIsLoading(true);
                await props.onAddNewContact(
                    {
                        userId: '',
                        userName: props.user,
                        name,
                        contactNo: contactNumber,
                        receivedProfile: props.activeProfileId,
                        company,
                        title,
                        email,
                        note,
                    },
                );
                showToast("Contact added successfully!", props.themeColor);
                props.onClose();
                props.onPostAnalyticInfo(postData);
            } catch (e) {
                showToast("Failed to add contact, please try again", props.themeColor);
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const formIsValid = () => {
        if (name.trim() === '') {
            showToast('Name cannot be empty', props.themeColor);
            return false;
        }

        if (contactNumber.trim() === '') {
            showToast('Contact number cannot be empty', props.themeColor);
            return false;
        }

        if (!isValidPhoneNumber(contactNumber)) {
            showToast('Invalid phone number', props.themeColor);
            return false;
        }

        if (email.trim() !== '') {
            const atposition = email.indexOf('@');
            const dotposition = email.lastIndexOf('.');

            if (
                atposition === -1 ||
                dotposition === -1 ||
                atposition < 1 ||
                dotposition < atposition + 2 ||
                dotposition + 2 >= email.length
            ) {
                showToast('Invalid email address', props.themeColor);
                return false;
            }
        }

        return true;
    };

    const handlePhoneChange = (phone) => {
        setContactNumber('+' + phone);
    };

    return (
        <div className={classes.AddNewContactPopup}>
            <div className={classes.CloseButtonContainer}>
                <div onClick={props.onClose}>
                    <MdClose color="#C5C5C5" size={20}/>
                </div>
            </div>
            <p className={classes.Title}>Contact Details</p>
            <div style={{
                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 80)} 100%)`
            }}
                 className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <BsPerson color="#919191" size={16}/>
                    </div>
                    <input
                        placeholder="Name"
                        inputMode="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
            </div>
            <div style={{
                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 80)} 100%)`
            }} className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <BsBriefcase color="#919191" size={13}/>
                    </div>
                    <input
                        placeholder="Company"
                        inputMode="text"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                </div>
            </div>
            <div style={{
                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 80)} 100%)`
            }} className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <FiAnchor color="#919191" size={13}/>
                    </div>
                    <input
                        placeholder="Title"
                        inputMode="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
            </div>
            <div style={{
                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 80)} 100%)`
            }} className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <BsTelephone color="#919191" size={13}/>
                    </div>
                    <PhoneInput
                        country={getCountry()}
                        placeholder="Contact Number"
                        countryCodeEditable={false}
                        value={contactNumber}
                        onChange={handlePhoneChange}
                        enableSearch={true}
                        disableSearchIcon={true}
                        inputClass={classes.PhoneInput}
                        buttonClass={classes.ButtonClass}
                        dropdownClass={classes.DropdownClass}
                        searchClass={classes.SearchClass}
                    />
                </div>
            </div>
            <div style={{
                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 80)} 100%)`
            }} className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <BsEnvelope color="#919191" size={13}/>
                    </div>
                    <input
                        placeholder="Email"
                        inputMode="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <div style={{
                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 80)} 100%)`
            }} className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <BiNote color="#919191" size={13}/>
                    </div>
                    <input
                        placeholder="Note"
                        inputMode="text"
                        value={note}
                        onChange={e => setNote(e.target.value)}
                    />
                </div>
            </div>

            {isLoading ? (
                <div className={classes.SpinnerContainer}>
                    <ClipLoader color={props.themeColor} loading={true} size={20}/>
                </div>
            ) : (
                <button style={{
                    background: `linear-gradient(91.3deg, ${props.themeColor} 1.61%, ${createGradient(props.themeColor, 50)} 100%)`
                }}
                        className={classes.SaveButton}
                        onClick={onSaveHandler}
                >
                    Save
                </button>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        loading: state.contact.loading,
        profiles: state.home.profiles,
        themeColor: state.home.status.themeColor,
        activeProfileId: state.home.status.activeProfileId,
        products: state.products.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddNewContact: (contactData) =>
            dispatch(actions.addContact(contactData)),
        onPostAnalyticInfo: data => dispatch(actions.postAnalyticsInfo(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewContactPopup);
