import classes from "./SetAsAdminConfirmationPopup.module.scss";
import {MdOutlineClose} from "react-icons/md";
import React from "react";
import {createGradient} from "shared/constants";

const SetAsAdminConfirmationPopup = ({onClose, onConfirm, username, color}) => {
  return (
    <div className={classes.SetAsAdminConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Action Confirmation
      </p>
      <p className={classes.InfoText}>
        Company admins can manage all users, profiles and company details.
        <br/>
        <br/>
        Are you sure you want to set <strong>{username}</strong> as a Company Admin?
      </p>
      <button
        className={classes.ConfirmButton}
        style={{
          background:
            `linear-gradient(91.3deg, 
                ${color} 
                1.61%, 
                ${createGradient(color, 50)} 
                100%)`
        }}
        onClick={onConfirm}
      >
        Yes
      </button>
    </div>
  );
};

export default SetAsAdminConfirmationPopup;
