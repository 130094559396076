export const GET_CONTACTS_START = 'GET_CONTACTS_START';

export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';

export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL';

export const CHANGE_SELECTED_ID = 'CHANGE_SELECTED_ID';

export const TOGGLE_EXPORT_IMPORT_DROPDOWN = 'TOGGLE_EXPORT_IMPORT_DROPDOWN';

export const ADD_NEW_CONTACT_START = 'ADD_NEW_CONTACT_START';

export const ADD_NEW_CONTACT_END  = 'ADD_NEW_CONTACT_END';