import React from 'react';
import classes from './Contacts.module.scss';
import Header from './Header/Header';
import {BsPlus} from 'react-icons/bs';
import {FiSearch} from 'react-icons/fi';
import Categories from './Categories/Categories';
import Contact from './Contact/Contact';
import {CgExport, CgImport} from 'react-icons/cg';
import ContactDetailPopup from './ContactDetailPopup/ContactDetailPopup';
import Backdrop from 'components/UI/Backdrop/Backdrop';
import BackgroundLeft from 'assets/images/myContacts/backgroundLeft.png';
import BackgroundRight from 'assets/images/myContacts/backgroundRight.png';
import BackgroundSmall from 'assets/images/myContacts/backgroundSmall.png';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import {exportToDevice, exportToGoogle, importFromDevice, importFromGoogle,} from 'shared/contactUtils';
import AddNewContactPopup from './AddNewContactPopup/AddNewContactPopup';
import {useOutletContext} from 'react-router-dom';
import {adjustColorBrightness} from 'shared/constants'
import {Spin} from "antd";

const Contacts = props => {
    const outletContext = useOutletContext();

    const [dropdownVisible, setDropdownVisibility] = React.useState(false);

    const [addNewContactPopupVisible, setAddNewContactPopupVisibility] =
        React.useState(false);

    const [contactDetailPopupVisible, setContactDetailPopupVisible] =
        React.useState(false);

    const [searchText, setSearchText] = React.useState('');

    const [contactId, setContactId] = React.useState();

    const useOutsideAlerter = ref => {
        React.useEffect(() => {
            const handleClickOutside = event => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdownVisibility(false);
                }
            };
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef);

    React.useEffect(() => {
        if (props.contacts.length === 0) {
            props.onGetAllContacts();
        }
    }, []);

    const toggleDropdownVisibility = () => {
        const visible = dropdownVisible;

        setDropdownVisibility(!visible);
    };

    const importFromDeviceHandler = () => {
        importFromDevice(
            props.user.id,
            props.onAddContact,
            props.onGetAllContacts,
            props.themeColor
        );
        setDropdownVisibility(false);
    };

    const importFromGoogleHandler = () => {
        importFromGoogle(
            props.user.id,
            props.onAddContact,
            props.onGetAllContacts,
            props.themeColor
        );
        props.onToggleExportImportDropdown();
    };

    const exportToDeviceHandler = () => {
        exportToDevice(props.contacts, props.themeColor);
        props.onToggleExportImportDropdown();
    };

    const exportToGoogleHandler = () => {
        exportToGoogle(props.contacts, props.themeColor);
        props.onToggleExportImportDropdown();
    };

    function escapeRegExp(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    }

    const searchTextRegex = new RegExp(escapeRegExp(searchText.toLowerCase()), 'i');

    const filteredContacts = props?.contacts?.filter((contact) => {
        return (
            contact.name.toLowerCase().match(searchTextRegex) ||
            contact.contactNo.toLowerCase().match(searchTextRegex)
        );
    });

    const mappedProfiles = props?.profiles?.reduce(
        (accumulator, current) => {
            accumulator.ids.push(current.id);
            accumulator.names.push(current.name);
            return accumulator;
        },
        {ids: [], names: []}
    );


    const setIdForContactFilter = (index) => {
        props.onGetContactsByProfileId(index);
    };

    const GetAll = () => {
        return () => {
            props.onGetAllContacts();
        };
    };

    return (
        <div className={classes.Contacts}>
            {props.isContactsLoading &&
                <Spin tip="Loading" size={"large"} style={{color: props.themeColor}} fullscreen/>}
            {contactDetailPopupVisible ? (
                <Backdrop onClick={() => setContactDetailPopupVisible(false)}>
                    <ContactDetailPopup
                        contactId={contactId}
                        onClose={() => {
                            setContactDetailPopupVisible(false)
                        }
                        }
                    />
                </Backdrop>
            ) : null}

            {addNewContactPopupVisible ? (
                <Backdrop onClick={() => setAddNewContactPopupVisibility(false)}>
                    <AddNewContactPopup
                        onClose={() => {
                            setAddNewContactPopupVisibility(false)
                        }}
                    />
                </Backdrop>
            ) : null}

            <input
                type="file"
                multiple="multiple"
                id="inpImportFromDevice"
                style={{display: 'none'}}
            />

            {dropdownVisible ? (
                <div
                    className={classes.DropDownPanel}
                    id="dropDownPanel"
                    ref={wrapperRef}
                >
                    <div className={classes.DropDownSectionContainer}>
                        <div className={classes.DropDownSection}>
                            <div
                                className={classes.DropDownOption}
                                onClick={exportToDeviceHandler}
                            >
                                <CgExport color="#D0D0D0" size={20}/>
                                <p>Export to Device</p>
                            </div>
                            <div
                                className={classes.DropDownOption}
                                onClick={exportToGoogleHandler}
                            >
                                <CgExport color="#D0D0D0" size={20}/>
                                <p>Export to Google</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Line}></div>
                    <div className={classes.DropDownSectionContainer}>
                        <div className={classes.DropDownSection}>
                            <div
                                className={classes.DropDownOption}
                                onClick={importFromDeviceHandler}
                            >
                                <CgImport color="#D0D0D0" size={20}/>
                                <p>Import from Device</p>
                            </div>
                            <div
                                className={classes.DropDownOption}
                                onClick={importFromGoogleHandler}
                            >
                                <CgImport color="#D0D0D0" size={20}/>
                                <p>Import from Google</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <Header
                toggleDropdownVisibility={toggleDropdownVisibility}
                toggleMenu={outletContext.toggleMenu}
            />
            <div style={{backgroundColor: props.color}} className={classes.TopContainer}>
                <img src={BackgroundLeft} alt="Commercial Prime"/>
                <img src={BackgroundRight} alt="Commercial Prime"/>
                <img src={BackgroundSmall} alt="Commercial Prime"/>
                <div className={classes.TopInnerContainer}>
                    <p>
                        Keep adding more contacts
                        <br/>
                        to grow your circle
                    </p>
                    <div className={classes.ButtonContainer}>
                        <button style={{backgroundColor: adjustColorBrightness(props.color, 50)}}
                                onClick={() => setAddNewContactPopupVisibility(true)}>
                            <BsPlus size={20}/>
                            &nbsp;Add Contact
                        </button>
                    </div>
                </div>
            </div>
            <div style={{borderColor: props.color}} className={classes.SearchInputContainer}>
                {/* <FiSearch color="#838383" /> */}
                <FiSearch color="#919191"/>
                <input
                    placeholder="Search Contacts..."
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                />
            </div>
            <div className={classes.BottomContainer}>
                <Categories profiles={mappedProfiles} setId={setIdForContactFilter} getAll={GetAll()}
                            selectedId={props.selectedId}/>
                <div className={classes.BottomInnerContainer}>
                    {filteredContacts.map((contact, index) => {
                        return (
                            <Contact
                                key={index}
                                color={props.color}
                                onClick={() => {
                                    setContactId(contact.id);
                                    setContactDetailPopupVisible(true)
                                }}
                                contact={contact}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        contacts: state.contact.contacts,
        dropdownVisible: state.contact.exportImportDropdownVisible,
        color: state.home.status.themeColor,
        profiles: state.home.profiles,
        selectedId: state.contact.selectedId,
        isContactsLoading: state.contact.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetAllContacts: () => dispatch(actions.getAllContacts()),
        onAddContact: (contact) =>
            dispatch(actions.addContact(contact)),
        onGetContactsByProfileId: profileId => dispatch(actions.getContactsByProfileId(profileId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
