import React from 'react';
import classes from './CreateOrEditProfilePage.module.scss';
import CreateOrEditProfile from "../../components/CreateProfile/CreateOrEditProfile";
import {useParams} from "react-router-dom";

const CreateOrEditProfilePage = ({mode}) => {
    const { profileId } = useParams();

    return (
        <div className={classes.CreateProfilePage}>
            <CreateOrEditProfile mode={mode} profileId={profileId}/>
        </div>
    );
};

export default CreateOrEditProfilePage;
