import React from 'react';
import classes from './DashboardPage.module.scss';
import Div100vh from 'react-div-100vh';

import DashboardHeader from 'components/Dashboard/DashboardHeader/DashboardHeader';
import DashboardBody from 'components/Dashboard/DashboardBody/DashboardBody';
import PrimeMobileMenu from 'components/UI/PrimeMenu/PrimeMobileMenu';
import PrimeDesktopMenu from 'components/UI/PrimeTabMenu/PrimeDesktopMenu';
import {CgExport, CgImport} from 'react-icons/cg';

import {connect} from 'react-redux';
import * as actions from 'store/actions';
import {exportToDevice, exportToGoogle, importFromDevice, importFromGoogle,} from 'shared/contactUtils';

const DashboardPage = props => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const useOutsideAlerter = ref => {
    React.useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          props.onToggleExportImportDropdown();
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  // React.useEffect(() => {
  //   props.onGetAllContacts();
  // }, []);

  const toggleMenuHandler = () => {
    setMenuOpen(!menuOpen);
  };

  const importFromDeviceHandler = () => {
    importFromDevice(
        props.user.id,
        props.onAddContact,
        props.onGetAllContacts,
        props.themeColor
    );
    props.onToggleExportImportDropdown();
  };

  const importFromGoogleHandler = () => {
    importFromGoogle(
        props.user.id,
        props.onAddContact,
        props.onGetAllContacts,
        props.themeColor
    );
    props.onToggleExportImportDropdown();
  };

  const exportToDeviceHandler = () => {
    exportToDevice(props.contacts, props.themeColor
    );
    props.onToggleExportImportDropdown();
  };

  const exportToGoogleHandler = () => {
    exportToGoogle(props.contacts, props.themeColor);
    props.onToggleExportImportDropdown();
  };


  return (
      <Div100vh
          className={classes.DashboardPage}
          style={{overflow: menuOpen ? 'auto' : 'auto'}}
      >
        <input
            type="file"
            multiple="multiple"
            id="inpImportFromDevice"
            style={{display: 'none'}}
        />

        {props.dropdownVisible ? (
            <div className={classes.DropDownPanel} ref={wrapperRef}>
              <div className={classes.DropDownSectionContainer}>
                <div className={classes.DropDownSection}>
                  <div
                      className={classes.DropDownOption}
                      onClick={exportToDeviceHandler}
                  >
                    <CgExport color={props.themeColor} size={20}/>
                    <p>Export to Device</p>
                  </div>
                  <div
                      className={classes.DropDownOption}
                      onClick={exportToGoogleHandler}
                  >
                    <CgExport color={props.themeColor} size={20}/>
                    <p>Export to Google</p>
                  </div>
                </div>
              </div>
              <div className={classes.Line}></div>
              <div className={classes.DropDownSectionContainer}>
                <div className={classes.DropDownSection}>
                  <div
                      className={classes.DropDownOption}
                      onClick={importFromDeviceHandler}
                  >
                    <CgImport color={props.themeColor} size={20}/>
                    <p>Import from Device</p>
                  </div>
                  <div
                      className={classes.DropDownOption}
                      onClick={importFromGoogleHandler}
                  >
                    <CgImport color={props.themeColor} size={20}/>
                    <p>Import from Google</p>
                  </div>
                </div>
              </div>
            </div>
        ) : null}
        <DashboardHeader
            toggleMenu={toggleMenuHandler}
            toggleDropdownVisibility={props.onToggleExportImportDropdown}
        />
        <PrimeDesktopMenu/>
        <PrimeMobileMenu toggleMenu={toggleMenuHandler} menuOpen={menuOpen} themeColor={props.themeColor}
                         role={props.role}/>
        <DashboardBody toggleMenu={toggleMenuHandler}/>
      </Div100vh>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    contacts: state.contact.contacts,
    dropdownVisible: state.contact.exportImportDropdownVisible,
    themeColor: state.home.status.themeColor,
    role: state.auth.userRole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllContacts: () => dispatch(actions.getAllContacts()),
    onAddContact: (contact, successCallback) =>
        dispatch(actions.addContact(contact, successCallback)),
    onToggleExportImportDropdown: () =>
        dispatch(actions.toggleExportImportDropdown()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
