import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';

export const uploadFile = (file, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        const formData = new FormData();
        formData.append('file', file);

        return axios
            .post('/api/user-service/upload-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const saveProfile = (requestData, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        return axios
            .post('/api/user-service/create-profile', requestData)
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const updateProfile = (requestData, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        return axios
            .post('/api/user-service/update-profile', requestData)
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const deleteProfile = (profileId, successCallback, showToast) => {
    return dispatch => {
        return axios
            .delete(`/api/user-service/delete-profile?profileId=${profileId}`)
            .then(() => {
                if (successCallback) successCallback();
            })
            .catch(err => {
                showToast('Cannot delete active profile')
                console.log(err)
                throw err;
            });
    };
};

export const setProfileData = payload => {
    return {
        type: actionTypes.SET_PROFILE_DATA,
        payload,
    };
};

export const getProfileData = (profileId) => {
    return dispatch => {
        axios
            .get('/api/user-service/get-profile', {params: {profileId}})
            .then((res) => {
                console.log(res.data);
                // dispatch(
                //     setProfileData({
                //         profilePicture,
                //         profileName,
                //         profileFields,
                //         darkMode,
                //     }),
                // );
                // dispatch(clearData());
            })
            .catch(err => console.log(err.response.data));
    };
}

// export const selectProfile = (endPoint, profileId) => {
//     return dispatch => {
//         axios
//             .get(endPoint, {params: {profileId}})
//             .then(res => {
//
//                 const darkMode = res.data.darkMode;
//
//                 let profilePicture = {
//                     id: null,
//                     name: 'Profile Picture',
//                     icon: '',
//                     value: '',
//                     visibility: VISIBILITY.PUBLIC,
//                 };
//
//                 const profileName = res.data.name ? res.data.name : '';
//
//                 const leftProfileFields = [];
//                 const rightProfileFields = [];
//                 const additionalProfileFields = [];
//
//                 const leftFieldNames = {
//                     'First Name': {icon: 'FaUser'},
//                     'Last Name': {icon: 'FaUser'},
//                     'Company': {icon: 'FaBriefcase'},
//                     'Title': {icon: 'FaAnchor'},
//                     'Contact Number': {icon: 'FaPhone'},
//                     'Email': {icon: 'FaEnvelope'},
//                     'Description': {icon: 'FaBolt'},
//                 };
//
//                 const rightFieldNames = {
//                     'Website': {icon: 'FaLink'},
//                     'WhatsApp': {icon: 'FaWhatsapp'},
//                     'Facebook': {icon: 'FaFacebook'},
//                     'Instagram': {icon: 'FaInstagram'},
//                     'LinkedIn': {icon: 'FaLinkedin'},
//                     'X': {icon: 'FaXTwitter'},
//                 };
//
//                 const profilePictureObj = res.data.data.find(
//                     field => field.name === 'Profile Picture',
//                 );
//
//                 if (profilePictureObj) {
//                     profilePicture = {
//                         ...profilePictureObj,
//                         visibility: profilePictureObj.publicVisible
//                             ? VISIBILITY.PUBLIC
//                             : VISIBILITY.PRIVATE,
//                     };
//                 }
//
//                 Object.keys(leftFieldNames).forEach(name => {
//                     const fieldObj = res.data.data.find(field => field.name === name);
//
//                     if (fieldObj) {
//                         leftProfileFields.push({
//                             ...fieldObj,
//                             visibility: fieldObj.publicVisible
//                                 ? VISIBILITY.PUBLIC
//                                 : VISIBILITY.PRIVATE,
//                         });
//                     }
//                 });
//
//                 Object.keys(rightFieldNames).forEach(name => {
//                     const fieldObj = res.data.data.find(field => field.name === name);
//
//                     if (fieldObj) {
//                         rightProfileFields.push({
//                             ...fieldObj,
//                             visibility: fieldObj.publicVisible
//                                 ? VISIBILITY.PUBLIC
//                                 : VISIBILITY.PRIVATE,
//                         });
//                     }
//                 });
//
//                 res.data.data.forEach(field => {
//                     const alreadyInLeft = Object.keys(leftFieldNames).find(
//                         name => field.name === name,
//                     );
//                     const alreadyInRight = Object.keys(rightFieldNames).find(
//                         name => field.name === name,
//                     );
//
//                     if (
//                         !alreadyInLeft &&
//                         !alreadyInRight &&
//                         field.name !== 'Profile Picture'
//                     ) {
//                         additionalProfileFields.push({
//                             ...field,
//                             visibility: field.publicVisible
//                                 ? VISIBILITY.PUBLIC
//                                 : VISIBILITY.PRIVATE,
//                         });
//                     }
//                 });
//
//                 dispatch(
//                     setProfileData({
//                         profilePicture,
//                         profileName,
//                         leftProfileFields,
//                         rightProfileFields,
//                         additionalProfileFields,
//                         darkMode,
//                     }),
//                 );
//             })
//             .catch(err => console.log('error :' + err));
//     };
// };

// export const changeDarkTheme = () => {
//     return {
//         type: actionTypes.CHANGE_DARK_MODE,
//     };
// }
