import * as actionTypes from '../actions/account/actionTypes';

const initialState = {
  accountDetails: null,
  loading: false,
  error: null
};

const getAccountDetailsStart =(state,action) =>{
  return {
    ...state,
    loading: true,
    error: null,
  };

}

const getAccountDetailsSuccess =(state,action) => {
  return {
    ...state,
    loading: false,
    accountDetails: action.payload,
  };

}

const getAccountDetailsEnd = (state,action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_DETAILS_START:
      return getAccountDetailsStart(state,action)
    case actionTypes.GET_ACCOUNT_DETAILS_SUCCESS:
      return getAccountDetailsSuccess(state,action)
    case actionTypes.GET_ACCOUNT_DETAILS_FAIL:
      return getAccountDetailsEnd(state,action)
    default:
      return state;
  }
};

export default reducer;
