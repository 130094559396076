export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const SIGN_OUT = 'SIGN_OUT';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_END = 'REGISTER_END';

export const EXISTS_BY_USERNAME_START = 'EXISTS_BY_USERNAME_START';
export const EXISTS_BY_USERNAME_SUCCESS = 'EXISTS_BY_USERNAME_SUCCESS';
export const EXISTS_BY_USERNAME_FAIL = 'EXISTS_BY_USERNAME_FAIL';



