import classes from '../Wrapper/Wrapper.module.scss';
import {GoPlus} from 'react-icons/go';
import {BiSearchAlt} from 'react-icons/bi';
import {RiShieldUserFill} from 'react-icons/ri';
import {MdArrowDropDown} from 'react-icons/md';
import {Button, Input, Space, Table, Tag} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import button from "../../../../../UI/Button/Button";
import {connect} from "react-redux";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import * as actions from '../../../../../../store/actions';
import ProfileLimitReached from "../../../../../UI/ProfileLimitReached/ProfileLimitReached";
import {USER_ROLE, USER_STATUS} from "../../../../../../common/enums";
import CreateOrUpdateUser from "./CreateOrUpdateUser/CreateOrUpdateUser";

const Users = (props) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [newUserIsOpen, setNewUserIsOpen] = useState(false);
    const [updateUserIsOpen, setUpdateUserIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [searchTerm, setSearchTerm] = useState(undefined);
    const [userLimitIsReached, setUserLimitIsReached] = useState(false);
    const searchInput = useRef(null);
    const [filteredData, setFilteredData] = useState([]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    useEffect(() => {
        console.log(props.users)
        if (props.users.length === 0) {
            props.onGetCompanyUsers();
        }
    }, []);


    useEffect(() => {
        console.log(props.users)
        setFilteredData(searchTerm != undefined ? props.users.filter((user) =>
            user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.includes(searchTerm) ||
            user.contactNumber.includes(searchTerm)) : props.users);
    }, [searchTerm, props.users]);

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText(undefined);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                    backgroundColor: '#141414',
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        borderColor: props.themeColor,
                        color: props.themeColor,
                        backgroundColor: "transparent",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<BiSearchAlt/>}
                        size="small"
                        style={{
                            width: 90,
                            background: props.themeColor,
                            color: "black",
                            gap: 5
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                            color: props.themeColor,
                            borderColor: props.themeColor,
                            backgroundColor: "transparent",
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <MdArrowDropDown
                size={20}
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (

                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const itemRender = (_, type, originalElement) => {
        if (type === 'next') {
            return <a className={classes.PaginationNext}>Next</a>;
        }
        return originalElement;
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // sorter: (a, b) => a.name - b.name,
            // ...getColumnSearchProps('name'),
            render: (_, record) => (
                <span style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap'}}>
                    {record.firstName} {record.lastName}
                    {record.role === USER_ROLE.COMPANY_ADMIN &&
                        <RiShieldUserFill size={16} style={{color: props.themeColor}}/>}
                </span>
            )
        },
        {
            title: 'Contact Number',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            // ...getColumnSearchProps('contactNumber'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // ...getColumnSearchProps('email'),
        },
        {
            title: 'Assigned Products',
            dataIndex: 'products',
            key: 'products',
            align: 'center',
            // ...getColumnSearchProps('products'),
            width: '25%',
            render: (value) => (
                <div style={{display: 'flex', justifyContent: 'center', gap: 10, flexWrap: 'wrap'}}>
                    {value.length > 0 ?
                        value.map((product) => (
                            <Tag style={{
                                backgroundColor: "transparent",
                                color: props.themeColor,
                                borderColor: props.themeColor,
                                fontSize: "smaller",
                                margin: 0,
                            }}>
                                {product.name} - {product.link}
                            </Tag>
                        )) :
                        <span style={{color: props.themeColor, fontSize: 'smaller'}}>No Products Assigned</span>}
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '10%',
            render: (value) => (
                <h4 style={{
                    margin: 0,
                    fontSize: 13,
                    fontWeight: 600,
                    color: value === USER_STATUS.ACTIVE ? '#50BC4E' : '#CD3738'
                }}>
                    {value}
                </h4>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, row) => (
                <button
                    onClick={() => {
                        setUserData(row)
                        setUpdateUserIsOpen(true)
                    }}
                    className={classes.ButtonInsideTable}
                >
                    Update
                </button>),
            align: 'center',
            fixed: 'right',
            width: '10%',
        },
    ];


    return (
        <section className={classes.Wrapper}>
            {newUserIsOpen ? (
                <Backdrop onClick={() => setNewUserIsOpen(false)}>
                    <CreateOrUpdateUser
                        onClose={() => {
                            setNewUserIsOpen(false);
                        }}
                        mode={"create"}
                        themeColor={props.themeColor}
                    />
                </Backdrop>
            ) : null}
            {updateUserIsOpen ? (
                <Backdrop onClick={() => setUpdateUserIsOpen(false)}>
                    <CreateOrUpdateUser
                        mode={"edit"}
                        data={userData}
                        onClose={() => {
                            setUpdateUserIsOpen(false);
                        }}
                        themeColor={props.themeColor}
                    />
                </Backdrop>
            ) : null}

            {userLimitIsReached ? (
                <Backdrop onClick={() => setUserLimitIsReached(false)}>
                    <ProfileLimitReached
                        color={props.themeColor}
                        onClose={() => setUserLimitIsReached(false)}
                    />
                </Backdrop>
            ) : null}
            <div className={classes.TopContainer}>
                <div className={classes.Title}>
                    <h1>Users</h1>
                    {/*<span>{props.users.length}</span>*/}
                </div>
                <div className={classes.SearchContainer}>
                    <button onClick={() => {
                        if (props.users.length === 0) {
                            setNewUserIsOpen(true)
                        } else if (props?.users[0].users <= props.users.length) {
                            setUserLimitIsReached(true)
                        } else {
                            setNewUserIsOpen(true)
                        }
                    }}>
                        <GoPlus/>
                        Create New User
                    </button>
                    <div>
                        <BiSearchAlt/>
                        <input type="text" placeholder="Search Users..." value={searchTerm}
                               onChange={event => setSearchTerm(event.target.value)}/>
                    </div>
                </div>
            </div>

            <div className={classes.TableContainer}>
                <Table
                    rowKey="id"
                    // showSorterTooltip={true}
                    style={{
                        color: props.themeColor,
                    }}
                    loading={props.isUserDataLoading}
                    columns={columns}
                    dataSource={filteredData
                        // .toSorted((a, b) => a.role === USER_ROLE.COMPANY_ADMIN ? -1 : 1)
                        /*filteredData.reverse().map((user, index) => ({
                          key: index + 1,
                          name: (
                            <span style={{display:'flex',alignItems:'center'}}>
                              {user.firstName} {user.lastName}
                              {user.role === 'ROLE_COMPANY_ADMIN' && <RiShieldUserFill size={16} style={{color:props.themeColor, marginLeft:6}}/>}
                            </span>
                          ),
                          contacts: user.phoneNumber,
                          email: user.email,
                          qrCode: user.products === null ? "Not Assigned" : user.products?.products,
                          status: user.status.charAt(0).toUpperCase() + user.status.slice(1).toLowerCase(),
                          actions: '',
                          userData: user,
                        }))*/
                    }
                    pagination={{
                        pageSize: 9,
                        itemRender: itemRender
                    }}
                    size="small"
                    // scroll={{
                    //     x: 750,
                    // }}
                />

            </div>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        themeColor: state.home.status.themeColor,
        users: state.teams.users,
        isUserDataLoading: state.teams.isUserDataLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCompanyUsers: () => dispatch(actions.getUsers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
