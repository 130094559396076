import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';

import {applyMiddleware, combineReducers, compose, legacy_createStore as createStore,} from 'redux';
import {thunk} from 'redux-thunk';
import {Provider} from 'react-redux';

import authReducer from './store/reducers/auth';
import homeReducer from './store/reducers/home';
import contactsReducer from './store/reducers/contact';
import productsReducer from './store/reducers/products';
import accountReducer from './store/reducers/account';
import teamsReducer from './store/reducers/teams';
import analyticsReducer from "./store/reducers/analytics";
import settingsReducer from './store/reducers/settings';
import * as actionTypes from "./store/actions/auth/actionTypes";
import {ConfigProvider, theme} from "antd";

const appReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    contact: contactsReducer,
    products: productsReducer,
    account: accountReducer,
    teams: teamsReducer,
    analytics: analyticsReducer,
    settings: settingsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.SIGN_OUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
);

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
