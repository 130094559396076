export const GET_COMPANY_USERS_START = 'GET_COMPANY_USERS_START';
export const GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS';
export const GET_COMPANY_USERS_FAIL = 'GET_COMPANY_USERS_FAIL';

export const GET_COMPANY_PRODUCTS_START = 'GET_COMPANY_PRODUCTS_START';
export const GET_COMPANY_PRODUCTS_SUCCESS = 'GET_COMPANY_PRODUCTS_SUCCESS';
export const GET_COMPANY_PRODUCTS_FAIL = 'GET_COMPANY_PRODUCTS_FAIL';

export const CHANGE_EXPAND = 'CHANGE_EXPAND';
export const ADD_NEW_TEAM_USER_START = 'ADD_NEW_TEAM_USER_START';
export const ADD_NEW_TEAM_USER_SUCCESS = 'ADD_NEW_TEAM_USER_SUCCESS';
export const ADD_NEW_TEAM_USER_END = 'ADD_NEW_TEAM_USER_END';

export const UPDATE_COMPANY_USER_START = 'UPDATE_COMPANY_USER_START';
export const UPDATE_COMPANY_USER_SUCCESS = 'UPDATE_COMPANY_USER_SUCCESS';
export const UPDATE_COMPANY_USER_END = 'UPDATE_COMPANY_USER_END';

export const GET_ALL_TEAM_USER_PROFILES_START =
  'GET_ALL_TEAM_USER_PROFILES_START';
export const GET_ALL_TEAM_USER_PROFILES_SUCCESS =
  'GET_ALL_TEAM_USER_PROFILES_SUCCESS';
export const GET_ALL_TEAM_USER_PROFILES_FAIL =
  'GET_ALL_TEAM_USER_PROFILES_FAIL';

export const UPDATE_TEAMS_COMPANY_DETAILS_START =
  'UPDATE_TEAMS_COMPANY_DETAILS_START';
export const UPDATE_TEAMS_COMPANY_DETAILS_SUCCESS =
  'UPDATE_TEAMS_COMPANY_DETAILS_SUCCESS';
export const UPDATE_TEAMS_COMPANY_DETAILS_END =
  'UPDATE_TEAMS_COMPANY_DETAILS_END';

export const DELETE_TEAM_USER_START = 'DELETE_TEAM_USER_START';
export const DELETE_TEAM_USER_END = 'DELETE_TEAM_USER_END';

export const UPDATE_TEAMS_BRANDING_DETAILS_START =
  'UPDATE_TEAMS_BRANDING_DETAILS_START';
export const UPDATE_TEAMS_BRANDING_DETAILS_SUCCESS =
  'UPDATE_TEAMS_BRANDING_DETAILS_SUCCESS';
export const UPDATE_TEAMS_BRANDING_DETAILS_END =
  'UPDATE_TEAMS_BRANDING_DETAILS_END';

export const CHANGE_BRANDING_IMAGE = 'CHANGE_BRANDING_IMAGE';

export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';

export const GET_TEAMS_COMPANY_DETAILS_START =
  'GET_TEAMS_COMPANY_DETAILS_START';
export const GET_TEAMS_COMPANY_DETAILS_SUCCESS =
  'GET_TEAMS_COMPANY_DETAILS_SUCCESS';
export const GET_TEAMS_COMPANY_DETAILS_FAIL = 'GET_TEAMS_COMPANY_DETAILS_FAIL';

export const SET_COMPANY_DATA = 'SET_COMPANY_DETAILS';