import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';

const getAllProfilesStart = () => {
    return {
        type: actionTypes.GET_ALL_PROFILES_START,
    };
};

const getAllProfilesSuccess = payload => {
    return {
        type: actionTypes.GET_ALL_PROFILES_SUCCESS,
        payload,
    };
};

const getAllProfilesFail = () => {
    return {
        type: actionTypes.GET_ALL_PROFILES_FAIL,
    };
};

const getStatusStart = () => {
    return {
        type: actionTypes.GET_STATUS_START,
    };
};

const getStatusSuccess = payload => {
    return {
        type: actionTypes.GET_STATUS_SUCCESS,
        payload,
    };
};

const getStatusFail = () => {
    return {
        type: actionTypes.GET_STATUS_FAIL,
    };
};

export const clearProfileData = () => {
    return {
        type: actionTypes.CLEAR_PROFILE_DATA,
    };
};

export const getStatus = () => {
    return dispatch => {
        dispatch(getStatusStart());
        axios
            .get('api/user-service/get-status')
            .then(res => {
                console.log(res.data);
                dispatch(getStatusSuccess(res.data));
            }).catch(() => dispatch(getStatusFail()));
    };
};

export const getAllProfiles = () => {
    return dispatch => {
        dispatch(getAllProfilesStart());
        axios
            .get('api/user-service/get-profiles')
            .then(res => {
                console.log(res.data);
                if (res.data.includes("html")) {
                    getAllProfilesFail();
                    return;
                }
                // const profileData = {
                //     profiles: res.data,
                //     vcardStatus: res.data[0].accountStatus,
                //     themeColor: res.data[0].color,
                //     profileLimit: res.data[0].profileLimit,
                //     logo: res.data[0].logo,
                //     companyExists: res.data[0].companyExists,
                //     privacyMode: res.data[0].userPrivacyMode,
                //     tenantEnabledPrivacyMode: res.data[0].tenantPrivacyMode,
                //     companyName: res.data[0].companyName,
                // };
                dispatch(getAllProfilesSuccess(res.data));
            }).catch(() => dispatch(getAllProfilesFail()));
    };
};

export const changePrivacyMode = (value) => {
    return async (dispatch) => {
        dispatch(updateHighPrivacyModeStart());
        axios.post('/api/user-service/privacy-mode?privacyMode=' + value).then(res => {
            dispatch(updateHighPrivacyModeSuccess(value));

        }).catch(() => dispatch(updateHighPrivacyModeFail()));
    };
};


export const changeActiveProfile = (profileId) => {
    return dispatch => {
        return axios
            .post('/api/user-service/change-active-profile', {profileId})
            .then(() => {
                dispatch(getStatusSuccess({activeProfileId: profileId}));
            })
            .catch(err => {
                console.error(err);
                throw err;
            });
    };
};

export const getProfileData = (profileId) => {
    return dispatch => {
        dispatch(getProfileDataStart());
        return axios
            .get(`/api/user-service/get-profile`, {
                params: {profileId: profileId}
            })
            .then(res => {
                // dispatch(getProfileDataSuccess(res.data)); 
                return res.data;
            })
            .catch(err => {
                // dispatch(getProfileDataFail(err)); 
                throw err;
            });
    };
};

// Action Creators
const getProfileDataStart = () => ({type: 'GET_PROFILE_DATA_START'});
const getProfileDataSuccess = (profileData) => ({
    type: 'GET_PROFILE_DATA_SUCCESS',
    payload: profileData,
});
const getProfileDataFail = (error) => ({
    type: 'GET_PROFILE_DATA_FAIL',
    payload: error,
});

export const changeProfileStatus = (userId, newStatus) => {
    return dispatch => {
        dispatch(updateProfileStatusStart());
        axios
            .post('/api/user-service/change-product-status', null, {
                params: {
                    userId,
                    status: newStatus,
                },
            })
            .then(() => dispatch(updateProfileStatusSuccess(newStatus)))
            .catch(() => dispatch(updateProfileStatusFail()));
    };
};

const updateProfileStatusStart = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_STATUS_START,
    };
};

const updateProfileStatusSuccess = status => {
    return {
        type: actionTypes.UPDATE_PROFILE_STATUS_SUCCESS,
        payload: {
            profileStatus: status,
        },
    };
};

const updateProfileStatusFail = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_STATUS_FAIL,
    };
};

const updateHighPrivacyModeStart = () => {
    return {
        type: actionTypes.UPDATE_HIGH_PRIVACY_MODE_START,
    };
};

const updateHighPrivacyModeSuccess = isEnabled => {
    return {
        type: actionTypes.UPDATE_HIGH_PRIVACY_MODE_SUCCESS,
        payload: {
            highPrivacyMode: isEnabled,
        },
    };
};

const updateHighPrivacyModeFail = () => {
    return {
        type: actionTypes.UPDATE_HIGH_PRIVACY_MODE_FAIL,
    };
};
