import React from 'react';
import {Dropdown, Menu, Tooltip} from 'antd';
import {DownOutlined, LockOutlined, GlobalOutlined} from '@ant-design/icons';
import {MdLock, MdPublic, MdFileDownloadOff, MdDownload, MdArrowDropDown} from 'react-icons/md';
import {PROFILE_DATA_PRIVACY_MODE} from "../../../common/enums";
import {getLabelForPrivacyMode} from "../../../common/utils";

const PrivacyModeDropdown = ({privacyMode, onChange, disabled, color}) => {
    const getIcon = (mode) => {
        switch (mode) {
            case PROFILE_DATA_PRIVACY_MODE.PRIVATE:
                return <MdLock color="#a7a7a7" size={20}/>;
            case PROFILE_DATA_PRIVACY_MODE.PUBLIC_BLOCK_EXPORT:
                return (
                    <>
                        <MdPublic color="#a7a7a7" size={17}/>
                        <MdFileDownloadOff color="#a7a7a7" size={17}/>
                    </>
                );
            case PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT:
                return (
                    <>
                        <MdPublic color="#a7a7a7" size={17}/>
                        <MdDownload color="#a7a7a7" size={17}/>
                    </>
                );
            default:
                return null;
        }
    };

    const menu = (
        <Menu onClick={({key}) => onChange(key)}>
            <Menu.Item key={PROFILE_DATA_PRIVACY_MODE.PRIVATE} icon={<MdLock color="#a7a7a7" size={17}/>}>
                Private
            </Menu.Item>
            <Menu.SubMenu key="public" title="Public" icon={<MdPublic color="#a7a7a7" size={17}/>}>
                <Menu.Item key={PROFILE_DATA_PRIVACY_MODE.PUBLIC_BLOCK_EXPORT}
                           icon={<MdFileDownloadOff color="#a7a7a7" size={17}/>}>Block Export</Menu.Item>
                <Menu.Item key={PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT}
                           icon={<MdDownload color="#a7a7a7" size={17}/>}>Allow Export</Menu.Item>
            </Menu.SubMenu>
        </Menu>
    );

    return (
            <Dropdown overlay={menu} trigger={['click']} disabled={disabled} dropdownRef={menu}>
                <Tooltip title={getLabelForPrivacyMode(privacyMode)}>
                    <div style={{
                        width: "90px",
                        display: 'flex',
                        justifyContent: 'end',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        opacity: disabled ? 0.4 : 1
                    }}>
                        <span style={{
                            marginRight: '2px',
                            display: "flex",
                            justifyContent: "center",
                            gap: 2
                        }}>{getIcon(privacyMode)}</span>
                        <MdArrowDropDown size={20} color="#a7a7a7"/>
                    </div>
                </Tooltip>
            </Dropdown>
    );
};

export default PrivacyModeDropdown;